import React from "react";
import {
    Button,
    Edit,
    FunctionField,
    ReferenceField,
    SaveButton,
    SimpleForm,
    Toolbar,
    DateInput,
    useDataProvider, useGetIdentity
} from 'react-admin';
import DietTrainingInput from "../Fields/DietTrainingInput";
import {useFormState} from "react-final-form";
import {useHistory} from "react-router";
import {Save} from "@material-ui/icons";
import {fetchHydra} from "../../App";


export const GoBackToolbar = (props) => {
    const history = useHistory();
    const { values }   = useFormState();
    const { identity, loading: identityLoading }  = useGetIdentity()
    const onSuccess = () => {
        history.goBack();
    };

    const save = () => {
        fetchHydra(process.env.REACT_APP_API_URL +  values.id + `/assign_product_box`, {
            method: 'PUT',
            body: JSON.stringify({
                workout: props.record?.workout !== values.workout? values.workout:undefined,
                diet: props.record?.diet !== values.diet? values.diet:undefined,
                maxActiveAt: props.record?.maxActiveAt !== values.maxActiveAt? values.maxActiveAt:undefined
            })
        }).then(onSuccess)

        // onSuccess();
//        Promise.all(tasks).then( () => {
//            dataProvider.update('ingredient_dishes', {
//                data: {
//                    ...values,
//                }
//            }).then(() => onSuccess())
//        })

    }
    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};


const OrderEdit = (props) => {

    return (
        <Edit title='Przypisz dietę i trening'  {...props}>
            <SimpleForm toolbar={<GoBackToolbar/>}>
                <ReferenceField label="Imię i nazwisko" source="client" reference="clients">
                    <FunctionField render={record => `${record.name} ${record.surname}`} />
                </ReferenceField>

                <DietTrainingInput/>
                <DateInput source="maxActiveAt" />
            </SimpleForm>

        </Edit>
    )
}

export default OrderEdit;