import React from "react";
import {Datagrid, EditButton, Filter, FunctionField, List, NumberField, ReferenceField, TextInput} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";


const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const couponStatus  = ['Aktywny', 'Niekatywny']

const SearchCoupon = props => (
    <Filter {...props}>
        <TextInput source={"code"} label={"Wyszukaj kupon"} alwaysOn/>
    </Filter>
)

 const CouponList = (props) => {

    return <List {...props} exporter={false} filters={<SearchCoupon />}>
        <Datagrid>
            <NumberField source='code' label="Numer kuponu" />
            <NumberField min={1} defaultValue={1} source='amountOfDiscount' label="Kwota zniżki" />
            <NumberField min={1} defaultValue={1} source='couponUsage' label="Ilość użyć" />

            <CuponField label="Osoba, która wygenerowała kupon" />

            <FunctionField source='status' label="Status" render={record => couponStatus[record.status] || 'brak danych'}/>

            <EditButton basePath='/discount_coupons' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/discount_coupons' label="Usuń" />
        </Datagrid>
    </List>
}


const CuponField = (props) => {


    return <>
        {props?.record?.clientOwner ?
        <ReferenceField source="clientOwner" reference="clients">
            <FunctionField render={record => record.name + " " + record.surname} />
        </ReferenceField>:   <ReferenceField source="adminOwner" reference="users">
                <FunctionField render={record => record.name + " " + record.surname} />
            </ReferenceField> }
    </>
    }

export default CouponList;