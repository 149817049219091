import React from "react";
import {Edit, SimpleForm, TextInput, DateInput, SelectInput, PasswordInput, ReferenceInput} from 'react-admin';
import {Button} from "@material-ui/core";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const UserEdit = (props) => {
    return (
        <Edit title='Edycja użytkownika'  {...props}>
            <SimpleForm redirect={'/users'}>
                <TextInput source='name' label="Imię"/>
                <TextInput source='surname' label="Nazwisko" />
                <TextInput source='email' label="Email"/>
                <ReferenceInput label="Grupa dostępu" source="group" reference="groups">
                    <SelectInput optionText="title" />
                </ReferenceInput>
                <SelectInput source='isActive' label="Dostęp" choices={[
                    {id: true, name: 'Tak'},
                    {id: false, name: 'Nie'}
                ]}/>
                <PasswordCustomInput source={'password'} label={'Zmiania hasła'}></PasswordCustomInput>
            </SimpleForm>

        </Edit>
    )
}

export default UserEdit;