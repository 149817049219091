import {useDataProvider, useRecordContext,Button} from "react-admin";
import {Checkbox} from "@material-ui/core";
import * as React from 'react';
import {useState} from "react";
import {useField} from "react-final-form";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Save} from "@material-ui/icons";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
};

function BasicModal({open,handleClose, handleConfirmed}) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title"  component="h2" style={{marginBottom: "10px"}}>
                        Czy na pewno oznaczyć jako Opłacone?
                    </Typography>
                    <Button label="Tak" variant={"text"} style={{paddingLeft: 0, justifyContent: "center"}} onClick={handleConfirmed}/>
                    <Button label="Nie" variant={"contained"} style={{paddingLeft: 0,justifyContent: "center"}} onClick={handleClose}/>
                </Box>
            </Modal>
        </div>
    );
}

function CheckboxField(props) {
    const { source } = props;
    const record = useRecordContext(props);
    const [checked, setChecked] = useState(record[source])
    const dateProvider = useDataProvider()
    const [confirmed, setConfirmed] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [target, setTarget] = useState(null);

    const handleOpen = (e) => {
        if (checked) {
            return null
        } else {
            setOpen(true);
            setTarget(e.target)
        }
    } ;

    const handleClose = () => setOpen(false);
    const handleConfirm = (e) => {
        setConfirmed(true)
        handleClose();
        if(!checked){
            setChecked(true)
            dateProvider.update('purchases', {id: record.id, data: {}, url: '/mark_as_paid'})
        }
    }

    // const changeStatus = (e) => {
    //
    //     if(!checked && confirmed){
    //         setChecked(e.target.checked)
    //         dateProvider.update('purchases', {id: record.id, data: {}, url: '/mark_as_paid'})
    //     }
    // }

    return (
        <>
            <BasicModal open={open} handleClose={handleClose} handleConfirmed={handleConfirm}/>
            <Checkbox onClick={handleOpen}  defaultChecked={checked} checked={checked}/>
        </>
    )
}

export default CheckboxField