import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    Filter, SearchInput, ReferenceField, NumberInput, NumberField, RichTextField, CloneButton, TextInput
} from 'react-admin';
import {Cancel} from "@material-ui/icons";


const SearchFilter = [
    <TextInput label={"Wyszukaj po nazwie"} source={"templateName"} resettable alwaysOn/>
]

const TrainingSchemaList = (props) => {

    return <List
        filters={SearchFilter}
        exporter={false}
        {...props}>
        <Datagrid>
            <TextField source={"templateName"} label={"Nazwa szablonu"}/>
            <TextField source={"workoutName"} label={"Nazwa treningu"}/>
            <RichTextField source={"shortWorkoutDescription"} label={"Rodzaj i krótki opis"}/>
            <RichTextField source={"trainingDays"} label={"Ilość dni treningowych"}/>

            <Cardio label={"Kardio"}/>
            <EditButton/>
            <CloneButton basePath='/workout_templates'/>
            <DeleteButton />
        </Datagrid>

    </List>
}

const Cardio = (props) => {
    return !props.record.isCardio?
        <BooleanField looseValue={true} source="isCardio" valueLabelTrue={'Kardio'} label={"Kardio"} {...props}/> : <NumberField source={"cardioTime"} label={"Kardio"} {...props}/>
}

export default TrainingSchemaList