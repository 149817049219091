import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput, ReferenceInput, ReferenceArrayField,ReferenceArrayInput, SingleFieldList, ChipField,SelectArrayInput
} from 'react-admin';


const QuestionCategoryEdit = (props) => {
    return (
        <Edit title='Edytuj kategorię'  {...props}>
            <SimpleForm>
                <TextInput source="title" label="Kategoria" />
                <NumberInput source={'questionCategoryOrder'} label={'Kolejność kategorii'}/>
            </SimpleForm>

        </Edit>
    )
}

export default QuestionCategoryEdit;