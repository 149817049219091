import {
    BooleanField,
    Create,
    List,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Button,
    DateInput,
    NumberField, NumberInput, Toolbar,
    useDataProvider
} from "react-admin";
import React from "react";
import "./table.css"
import {useHistory} from "react-router";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";

const GoBackToolbar = (props) => {
    const history      = useHistory();
    const { values }   = useFormState();
    const dataProvider = useDataProvider()

    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []

    const save = () => {

        delete values['orginId']
        delete values['@id']

        Promise.all(tasks).then(() => {
            dataProvider.create('diet_templates', {
                data: {
                    ...values,
                }
            }).then(() => onSuccess())
        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};

const TemplateCreate = (props) => {
    return <Create title='Nowy szablon' {...props}>
        <SimpleForm redirect={'/diet_templates'} toolbar={<GoBackToolbar/>}>
            <TextInput multiline source='templateName' label="Nazwa szablonu"/>
            <TextInput multiline source='dietName' label="Nazwa diety"/>
            <span>Ilość kalorii do spożycia w ciągu dnia:</span>
            <RichTextInput multiline source='caloriesConsumedDuringDay' label=""/>
            <span>Ilość posiłków do spożycia w ciągu dnia:</span>
            <RichTextInput multiline source='mealsConsumedDuringDay' label=""/>
            <span>Ilość propozycji dań do każdego z posiłków:</span>
            <RichTextInput multiline source='proposedDishesForEachMeal' label=""/>
            <span>Dodatkowo:</span>
            <RichTextInput multiline source='proposedDishesForEachMealAdditionalInfo' label=""/>
            <span>Dzienny podział makroskładników:</span>
            <RichTextInput multiline source='dailyDivisionOfMicronutrients' label=""/>
            <span>Dodatkowa przekąska liczba kcal:</span>
            <RichTextInput multiline source='additionalSnackCaloriesInfo' label=""/>
            <span>Dni nietreningowe:</span>
            <RichTextInput multiline source='additionalSnackNotTrainingDaysInfo' label=""/>

            <div style={{width: "100%"}}>
                <table className={"table2"} >
                    <tr>
                        <th></th>
                        <th>I śniadanie</th>
                        <th>II śniadanie</th>
                        <th>Obiad</th>
                        <th>II obiad</th>
                        <th>Kolacja</th>
                        <th>Łącznie</th>
                    </tr>
                    <tr>
                        <td>Białko(g)</td>
                        <td><TextInput source={"breakfastProtein"} label={""}/></td>
                        <td><TextInput source={"secondBreakfastProtein"} label={""}/></td>
                        <td><TextInput source={"lunchProtein"} label={""}/></td>
                        <td><TextInput source={"secondLunchProtein"} label={""}/></td>
                        <td><TextInput source={"dinnerProtein"} label={""}/></td>
                        <td><TextInput source={"totalProtein"} label={""}/></td>
                    </tr>
                    <tr>
                        <td>Węglowodany(g)</td>
                        <td><TextInput source={"breakfastCarbohydrate"} label={""}/></td>
                        <td><TextInput source={"secondBreakfastCarbohydrate"} label={""}/></td>
                        <td><TextInput source={"lunchCarbohydrate"} label={""}/></td>
                        <td><TextInput source={"secondLunchCarbohydrate"} label={""}/></td>
                        <td><TextInput source={"dinnerCarbohydrate"} label={""}/></td>
                        <td><TextInput source={"totalCarbohydrate"} label={""}/></td>
                    </tr>
                    <tr>
                        <td>Tłuszcze(g)</td>
                        <td><TextInput source={"breakfastFat"} label={""}/></td>
                        <td><TextInput source={"secondBreakfastFat"} label={""}/></td>
                        <td><TextInput source={"lunchFat"} label={""}/></td>
                        <td><TextInput source={"secondLunchFat"} label={""}/></td>
                        <td><TextInput source={"dinnerFat"} label={""}/></td>
                        <td><TextInput source={"totalFat"} label={""}/></td>
                    </tr>
                    <tr>
                        <td>Kalorie(Kcal)</td>
                        <td><TextInput source={"breakfastCalorie"} label={""}/></td>
                        <td><TextInput source={"secondBreakfastCalorie"} label={""}/></td>
                        <td><TextInput source={"lunchCalorie"} label={""}/></td>
                        <td><TextInput source={"secondLunchCalorie"} label={""}/></td>
                        <td><TextInput source={"dinnerCalorie"} label={""}/></td>
                        <td><TextInput source={"totalCalorie"} label={""}/></td>
                    </tr>
                </table>
            </div>

            {/* <PasswordInput source={'password'} label={'Zmiania hasła'}></PasswordInput> */}
        </SimpleForm>
    </Create>
}
export default TemplateCreate;