import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import {GoBackToolbar} from "../Meals/MealCreate";



const ProductCreate = (props) => {
    return (
        <Create  title='Stwórz produkt...'  {...props}>
            <SimpleForm  redirect={'/ingredients'}>
                <TextInput source="title" label="Nazwa" />
                <NumberInput source='value' label="Wartość produktu"/>
                <NumberInput source='fat' label="Tłuszcze na 100g"/>
                <NumberInput source='carbohydrate' label="Węglowodany na 100g"/>
                <NumberInput source='protein' label="Białko na 100g"/>
                <span>Cena podana w groszach</span>
                <NumberInput source='price' label="Cena produktu na 100g"/>
                <ReferenceArrayInput label="Kategoria zamienników" source="categories" reference="ingredient_categories">
                    <SelectArrayInput optionText="title" />
                </ReferenceArrayInput>
                {/*<ArrayInput source="substitutes" label={""}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput reference="ingredients" label="Zamiennik">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}
            </SimpleForm>

        </Create>
    )
}

export default ProductCreate;