import React, {useEffect, useState} from 'react'
import {Card, CardActions, CardContent, CardHeader, Icon, IconButton, TextField} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import generateAvatar from "./generateAvatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {format} from "date-fns";
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  color: #07a944;
  margin-top: 15px;
  text-decoration: none solid rgb(7, 169, 68);
  cursor: pointer;
  width: 100%;
`

function Notes(props) {
    const [edit, setEdit] = useState(false)
    const [note, setNote] = useState(undefined)

    const addNote = () => {
        const noteData = {
            noteContent: 'Notatka',
            client: props.record.client,
            updatedAt: new Date()
        }
        setEdit(true)
        setNote(noteData)
        fetch(process.env.REACT_APP_IMAGE_URL + `api/notes`, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            method: 'POST',
            body: JSON.stringify(noteData)
        }).then(res => res.json()).then(data => {
            if (data) {
                setNote(data)
            }
        })
    }

    useEffect(() => {
        if (props.record.client) {
            fetch(process.env.REACT_APP_IMAGE_URL + `${props.record.client.replace('/', '')}/notes`, {
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => res.json()).then(data => {
                if (data.length > 0) {
                    setNote(data[0])
                } else {
                    setNote(undefined)
                }
            })
        }

    }, [props.record.client])

    const toggleEdit = () => {
        if (edit) {
            fetch(process.env.REACT_APP_IMAGE_URL + `api/notes/${note.id}`, {
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                method: 'PUT',
                body: JSON.stringify(note)
            })
        }
        setEdit(!edit)
    }

    const deleteNote = () => {
        fetch(process.env.REACT_APP_IMAGE_URL + `api/notes/${note.id}`, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            method: 'DELETE',
        }).then(() => {
            setNote(undefined)
        })
    }

    return (
        <div style={{width: '100%'}}>
            {!!note ? <Card className="mb-32 overflow-hidden rounded-8 shadow">
                <CardHeader
                    title={
                        <span className="flex">
										<Typography className="font-medium" color="primary" paragraph={false}>
											Notatka
										</Typography>
									</span>
                    }
                    subheader={"Ostatnia modyfikacja: " + format(new Date(note.updatedAt), 'yyyy-MM-dd HH:mm')}
                />

                <CardContent className="py-0">

                    {!edit ? <div dangerouslySetInnerHTML={{__html: note.noteContent}} style={{
                            whiteSpace: 'pre-wrap',
                            overflow: 'auto',
                            maxHeight: '482px'
                        }} className="mb-16"/> :

                        <TextField
                            autoFocus
                            value={note.noteContent}
                            onChange={(e) => setNote({...note, noteContent: e.currentTarget.value})}
                            multiline
                            rows={12}
                            style={{width: '100%'}}
                            defaultValue="Default Value"
                            variant="outlined"
                        />}


                </CardContent>
                <CardActions disableSpacing className="px-12">
                    <Button onClick={toggleEdit} size="small" aria-label="Add to favorites">
                        {!edit ? <Icon className="text-16" color="action">
                            edit
                        </Icon> : <Icon className="text-16" color="action">
                            save
                        </Icon>}
                        <Typography className="mx-4">{!edit ? 'Edytuj' : "Zapisz"}</Typography>
                    </Button>
                    {/*<Button onClick={deleteNote}>*/}
                    {/*    <Icon className="text-16" color="action">*/}
                    {/*        delete*/}
                    {/*    </Icon>*/}
                    {/*    <Typography className="mx-4">Usuń</Typography>*/}
                    {/*</Button>*/}
                </CardActions>
            </Card> : <ButtonWrapper
                onClick={addNote}>
                <img
                    alt={'add icon'}
                    src={'/assets/icons/addicon.svg'}
                    style={{width: '17px', height: '17px', marginRight: '8px'}}
                />
                Dodaj notatkę
            </ButtonWrapper>}
        </div>
    );
};

export default Notes