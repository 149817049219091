import React, {useEffect} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    TextField,
    SelectInput,
    DateField,
    NumberInput,

    ArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput,
    SelectArrayInput, useGetList, minValue, AutocompleteInput, Button
} from 'react-admin';
import {validateGrams} from "../Meals/MealCreate";
import {useField} from "react-final-form";
import {omit} from "lodash/object";


const QuestionnaireListCreate = (props) => {

    const transform = async data => {
        if(data.questions && data.questions.length > 0){
            const mapped = data.questions.map(q => q.question)

            data.questions = mapped
        }

        return data;
    };

    return (
        <Create title='Nowa ankieta'  {...props} transform={transform}>
            <SimpleForm redirect={"/questionnaire_templates"}>
                <TextInput source='title' label="Nazwa"/>
                <DateInput source='createdAt' label="Data dodania" disabled={true} defaultValue={new Date()}/>
                <DateInput source='updatedAt' label="Data edycji" disabled={true} defaultValue={new Date()}/>
                <ReferenceArrayInput reference={'products'} source={'products'} label={"Przypisane pakiety"} perPage={30}>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                {/*<ArrayInput label="Pytania"*/}
                {/*            reference="questions"*/}
                {/*            source="questions">*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <TextInput source="question" label={"Pytanie"}/>*/}
                {/*        <NumberInput label={"Kolejność"} source={"questionOrder"}/>*/}
                {/*        <SimpleForm>*/}
                {/*            <ArrayInput label="Odpowiedzi"*/}
                {/*                        reference="answers"*/}
                {/*                        source="answers">*/}
                {/*                <SimpleFormIterator>*/}
                {/*                    <TextInput label="Odpowiedź" source={"answer"}/>*/}
                {/*                </SimpleFormIterator>*/}
                {/*            </ArrayInput>*/}
                {/*        </SimpleForm>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}
                <CategoryQuestion label={"Pytania"}/>
            </SimpleForm>

        </Create>
    )
}





const CategoryQuestion = (props) => {

    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'questions',
        {page:1, perPage:1000},
        undefined,
        {isClientQuestion: false}
    );


    const choices = (el) => {
        return Object.values(el).map( (e) => ({
            id: e.id,
            name: e.question
        }))
    }


    return <ArrayInput {...props} source={"questions"} label={''}>
        <SimpleFormIterator addButton={<CustomAddButton/>}>
            <AutocompleteInput label={'Pytanie'} source={'question'} choices={choices(data)}/>
        </SimpleFormIterator>
    </ArrayInput>
}

const CustomAddButton = (props) => {
    return <Button {...props} label={'Dodaj pytanie'}/>
}

export default QuestionnaireListCreate;