import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    FunctionField, ReferenceField, ShowButton, BooleanField, TextInput

} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange'}
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const postFilters = [
    <TextInput label="Id klienta" source="client.helperId" alwaysOn />,
];

const ClientMsgList = (props) => {

    const messageStatus = ["Przeczytana", "Nieprzeczytana"];

    return <List {...props} filters={postFilters} exporter={false} bulkActionButtons={false}>
        <Datagrid>
            <ReferenceField label="Imie i nazwisko" reference="clients" source="client">
                <FunctionField render={record => `${record.name} ${record.surname}`} />
            </ReferenceField>
            <ReferenceField label="E-mail" reference="clients" source="client">
                <TextField source="email" />
            </ReferenceField>
            <DateField source='updatedAt' label="Data ostatniej wiadomości"/>
            <ReferenceField link={false} label="Status" source="lastMessage" reference="messages">
                <FunctionField source='status' label="Status" render={record => messageStatus[Number(!record.isRead)]}/>
            </ReferenceField>
            <BooleanField label="Odpisano" source={'isAnsweredByAdmin'}/>

            <ShowButton label="Otwórz chat"/>
            {/*<DeleteButton basePath='/clientMsg' label="Usuń" />*/}
        </Datagrid>
    </List>
}

export default ClientMsgList;