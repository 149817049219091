import React, {cloneElement, useEffect, useState} from "react";
import {
    ArrayInput,
    AutocompleteInput,
    EditContextProvider,
    minValue,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SelectArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    useDataProvider,
    useEditController,
    useGetList, useNotify,
    useRecordContext,
    Button, useGetOne, FunctionField, useGetIdentity
} from 'react-admin';
import {CustomImageField} from "../Fields/CustomArrayImageField";
import RichTextInput from "ra-input-rich-text";
import {useHistory} from "react-router";
import {CircularProgress} from "@material-ui/core";
import {Add, ArrowDownward, ArrowUpward, EditAttributesSharp, Save} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {omit, pick} from "lodash/object";
import {validateGrams} from "./MealCreate";
import {useField, useFormState} from "react-final-form";
import axios from 'axios';
import refresh from "ra-core/lib/sideEffect/refresh";
import styled from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import {useTranslate} from "ra-core";
import ButtonRemove from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import useDebouncedEffect from "use-debounced-effect";

export const CardUl = styled.ul`
  list-style: none;
  padding: 10px;
  position: fixed;
  border: 1px solid;
  background-color: #E4E4E4;
  border-radius: 10px;
  box-shadow: 8px 8px 20px -20px rgba(66, 68, 90, 1);
  top: 50%;
  left: 40%;
  transform: translateY(-50%);
  z-index: 1000000;
  width: 100%;
  max-width: 300px;
  font-family: Roboto;


  @media (max-width: 999px) {
    transform: unset;
    top: 0;
    left: 0;
    max-width: unset;
    border-radius: 0;

    & button {
      position: absolute;
      bottom: 0;
      left: 50%;
      top: unset !important;
      right: unset !important;
    }
  }

  & li {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    //font-size: 20px;
  }

  & div:first-of-type {
    font-weight: bold;
  }

  & button {
    position: absolute;
    top: -27px;
    right: -29px;

    & :hover {
      color: #ffa84c;
    }
  }
`;

const MealEditToolbar = (props) => {
    const history = useHistory()

    const {values, ...rest} = useFormState();
    const dataProvider = useDataProvider();
    const notify = useNotify()


    const transform = async data => {
        if (data.ingredientsForDishes && data.ingredientsForDishes.length > 0) {
            const mapped = [];
            for (const ifd of data.ingredientsForDishes) {
                const body = omit(ifd, 'id');
                if (ifd.id && !props.embed) {
                    await dataProvider.update('ingredient_dishes', {
                        id: ifd.id,
                        data: {...body, ingredientDishOrder: data.ingredientsForDishes.indexOf(ifd)}
                    })

                    mapped.push(ifd.id)
                } else {
                    const record = await dataProvider.create('ingredient_dishes', {
                        data: {...body,
                            dish: null,
                            ingredientDishOrder: data.ingredientsForDishes.indexOf(ifd)}
                    })

                    mapped.push(record.data.id);
                }
            }

            data.ingredientsForDishes = mapped;
        }

        return data;
    };

    const save = async () => {

        if (props.embed) {
            delete values['originId']
            delete values['@id']
            delete values['id']

            dataProvider.create('dishes', {
                data: await transform({
                    ...values,
                    dietsBreakfastCount: 0,
                    dietsBreakfasts: [],
                    dietsCount: 0,
                    dietsDesserts: [],
                    dietsDessertsCount: 0,
                    dietsDinners: [],
                    dietsDinnersCount: 0,
                    dietsLunches: [],
                    dietsLunchesCount: 0,
                    dietsSecondBreakfastCount: 0,
                    dietsSecondBreakfasts: [],
                    dietsSecondLunches: [],
                    dietsSecondLunchesCount: 0,
                    dietsSnacks: [],
                    dietsSnacksCount: 0,
                })
            }).then((data) => {
                if (props.replaceDish) {
                    props.replaceDish(data.data)
                }
                if (props.closeModal) {
                    props.closeModal()
                }
            }).catch(e => {
                console.error(e)
                notify('Kod potrawy juz istnieje, musisz go zmienić!')
            })

        } else {
            dataProvider.update('dishes', {
                id: values.id,
                data: await transform(values)

            }).then((data) => {
                onSuccess()
            })
        }

    }

    const onSuccess = () => {
        if(props.closeModal) {
         return props.closeModal()
        }
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={'Wstecz'}/>
        </Toolbar>
    )
}

export const GoBackToolbar = (props) => {
    const history = useHistory();
    const onSuccess = () => {
        history.goBack();
    };
    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onClick={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
};
const ProductCreateButton = () => {
    const history = useHistory()
    const record = useRecordContext()
    const redirect = () => {
        history.push({pathname: '/ingredient_dishes/create', state: {dishId: record.id}})
    }
    return <Button onClick={redirect}><Add/>Nowy produkt</Button>
}
const ProductEditButton = () => {
    const record = useRecordContext();
    return <Button
        component={Link}
        to={{
            pathname: `/ingredient_dishes/${encodeURIComponent(record.id)}`,
            state: {record},
        }}
    >
        <EditAttributesSharp/>
        Edytuj
    </Button>
}

const OrderButtons = (props) => {
    const dataProvider = useDataProvider()
    const elIndex = Number(String(props.source).match(/\[(.*?)\]/)[1])

    const handlePosition = (pos) => (e) => {

        const el = props.ingredientsForDishes[elIndex]
        const swapEl = props.ingredientsForDishes[elIndex+pos]


        Promise.all([dataProvider.update('ingredient_dishes', {id: el.id, data: {ingredientDishOrder: elIndex+pos} }), dataProvider.update('ingredient_dishes', {id: swapEl.id, data: {ingredientDishOrder: elIndex} })]).then(data => {
            const copy = [...props.ingredientsForDishes]
            const elCopy = copy[elIndex]
            copy[elIndex] = copy[elIndex+pos]
            copy[elIndex+pos] = elCopy
            props.onChange(copy)
        })

    }

    return (
        <div className={"flex mb-12 justify-between"} style={{minWidth: "256px"}}>
            {elIndex !== 0 && <Button children={<ArrowUpward/>} className={"MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall"} onClick={handlePosition(-1)} label={"Do góry"}/>}
            {elIndex !== (props.ingredientsForDishes.length - 1) && <Button children={<ArrowDownward/>} onClick={handlePosition(1)} label={"Na dół"}/>}
        </div>
    )
}
const CustomEdit = props => {

    const [ingredients, setIngredients] = useState(null)
    const dataProvider = useDataProvider();
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(() => {
        props.getRecord(record)
    }, [record])

    useEffect(async () => {
        if (record?.ingredientsForDishes) {
            let arr = []
            for (const ingredient of record.ingredientsForDishes) {
                const record = await dataProvider.getOne('ingredient_dishes', {
                    id: ingredient
                })
                if (record?.data) {
                    arr.push(pick(record.data, ['grams', 'ingredient', 'dish', 'portionOfSauce', 'id', 'ingredientDishOrder']));
                }
            }
            setIngredients(arr)
        }
    }, [record?.ingredientsForDishes])
    if (!record) return <CircularProgress/>
    const newRecord = {
        ...controllerProps.record,
        ingredientsForDishes: ingredients?.sort((a, b) => a.ingredientDishOrder - b.ingredientDishOrder),
    }

    props.onGetIngredients(ingredients)
    return <EditContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </EditContextProvider>
}
const MealEdit = (props) => {
    const dataProvider = useDataProvider();
    const [openNutrition, setOpenNutrition] = useState(true);
    const [newRecord, setNewRecord] = useState({});
    const [ingredients, setIngredients] = useState([]);
    const [calculatedNutritional, setCalculatedNutritional] = useState(null);

    const getRecord = (record) => {
        setNewRecord(record)
    }

    const getIngredients = (record) => {
        setIngredients(record)
    }

    const getCalculatedNutritional = data => {
        return setCalculatedNutritional(data);
    }

    const transform = async data => {
        if (data.ingredientsForDishes && data.ingredientsForDishes.length > 0) {
            const mapped = [];
            for (const ifd of data.ingredientsForDishes) {
                const body = omit(ifd, 'id');
                if (ifd.id && !props.embed) {
                    await dataProvider.update('ingredient_dishes', {
                        id: ifd.id,
                        data: {...body, ingredientDishOrder: data.ingredientsForDishes.indexOf(ifd)}
                    })

                    mapped.push(ifd.id)
                } else {
                    const record = await dataProvider.create('ingredient_dishes', {
                        data: {...body, ingredientDishOrder: data.ingredientsForDishes.indexOf(ifd)}
                    })

                    mapped.push(record.data.id);
                }
            }

            data.ingredientsForDishes = mapped;
        }

        return data;
    };


    return (
        <CustomEdit title='Edytuj posiłek...' {...props} transform={transform} getRecord={getRecord} onGetIngredients={getIngredients}>
            <SimpleForm toolbar={<MealEditToolbar replaceDish={props.replaceDish} closeModal={props.closeModal}
                                                  dietId={props.dietId} embed={props.embed}/>} undoable={false}
                        keepDirtyOnReinitialize={true} redirect={'/dishes'}>
                <TextInput source="title" label="Nazwa potrawy"/>
                <TextInput source="dishCode" label="Kod potrawy"/>
                <ReferenceInput label={"Zdjęcia potrawy"} source="photo" reference="attachments">
                    <CustomImageField name={'photo'}/>
                </ReferenceInput>
                <ReferenceArrayInput label="Kategoria" source="categories" reference="dish_categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
                {openNutrition && calculatedNutritional && ingredients?.length > 0 && <CardUl>
                    <li>
                        <div>Tłuszcze</div>
                        <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishFats : newRecord.calculatedDishFats} g</div>
                    </li>
                    <li>
                        <div>Węglowodany</div>
                        <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishCarbohydrates : newRecord.calculatedDishCarbohydrates} g</div>
                    </li>
                    <li>
                        <div>Białko</div>
                        <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishProteins : newRecord.calculatedDishProteins} g</div>
                    </li>
                    <li>
                        <div>Ilość kalorii</div>
                        <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishCalorific : newRecord.calculatedDishCalorific} Kcal</div>
                    </li>
                    <button onClick={() => {
                        setOpenNutrition(false)
                    }
                    }><CancelIcon/></button>
                </CardUl>}
                <OrderedFormIterator
                    getCalculatedNutritional={getCalculatedNutritional}
                    initialRecord={newRecord}
                    ingredients={ingredients}
                    setOpenNutrition={setOpenNutrition}/>
                <RichTextInput multiline source="recipe" label="Przepis na potrawę"/>
                <NumberInput source='approximatePrepareTime' label="Czas przygotowywania"/>
            </SimpleForm>
        </CustomEdit>
    )
}

const OrderedFormIterator = (props) => {
    const {data, ids, total, loading, loaded, error, refetch} = useGetList(
        'ingredients',
        {page: 1, perPage: 1000}
    );
    const {input} = useField('ingredientsForDishes')
    const choices = (el) => {
        return Object.values(el).map((e) => ({
            id: e.id,
            name: e.title
        }))
    }
    const validateValue = [minValue(0, "Wartość nie może być ujemna"),];
    const [ingredientToCalculate, setIngredientToCalculate] = useState({})
    const [ingredientIndex, setIngredientIndex] = useState(null)
    const [calculatedNutritional, setCalculatedNutritional] = useState({})
    const [updatedIngredientsToCalculate, setUpdatedIngredientsToCalculate] = useState(null)
    const [newArr, setNewArr] = useState([])
    useEffect(() => {
        if (props.ingredients !== null && props.ingredients.length > 0) {
            setUpdatedIngredientsToCalculate(props.ingredients)
        }
    }, [props.ingredients])

    useEffect(() => {
        if(ingredientToCalculate && updatedIngredientsToCalculate?.length === 0){
            return setUpdatedIngredientsToCalculate([{...ingredientToCalculate, grams: 0}])
        }
        // setUpdatedIngredientsToCalculate(prev=> {
        //     if (prev?.length > 0) {
        //         return [...prev, ingredientToCalculate]
        //     }
        // })
        updatedIngredientsToCalculate?.map(ingredient => {

            if (ingredient?.id === ingredientToCalculate?.id) {
                setUpdatedIngredientsToCalculate(prev => {
                    if (Object.entries(prev).length === 0) {
                        return props.ingredients
                    } else {
                        const prevCopy = [...prev]
                        let isInArray = false
                        prevCopy.forEach((value, index) => {
                            if (index === ingredientIndex) {
                                prevCopy[index] = ingredientToCalculate;
                                isInArray = true
                            }
                        })
                        if (!isInArray) {
                            prevCopy.push(ingredientToCalculate);
                        }
                        return [...prevCopy];
                    }
                })
            }
        })
        if(updatedIngredientsToCalculate?.every(a => a.id !== ingredientToCalculate.id)){
            setUpdatedIngredientsToCalculate([...updatedIngredientsToCalculate, ingredientToCalculate])
        }

    }, [ingredientToCalculate])

    const DefaultRemoveButton = props => {
        const translate = useTranslate();

        return (
            <ButtonRemove size="small"  {...props} onClick={(e) => {
                updatedIngredientsToCalculate.splice(props.className.replace(/^[^0-9]+/, ''), 1)
                const newIngredientArray = [...updatedIngredientsToCalculate]
                setNewArr(newIngredientArray)
                props.onClick(e)
                fetch(process.env.REACT_APP_API_URL + `/api/dishes/calculate_info`, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({ingredientsForDishes: updatedIngredientsToCalculate})

                }).then(response => {
                    return response.json()
                }).then(data => {
                    setCalculatedNutritional(data)
                })

                if (updatedIngredientsToCalculate.length === 0) {
                    setCalculatedNutritional(prev => {
                        return {...prev,
                            calculatedDishCalorific: 0,
                            calculatedDishCarbohydrates: 0,
                            calculatedDishProteins: 0,
                            calculatedDishFats: 0,
                            calculatedDishPrice: 0}
                    })
                    props.setOpen(false)
                }
            }}>
                <CloseIcon/>
                {translate('ra.action.remove')}
            </ButtonRemove>
        );
    };

    useEffect(() => {
        if (newArr.length > 0) {
            setUpdatedIngredientsToCalculate(newArr)
        }
    }, [newArr])

    useDebouncedEffect(() => {
        if (ingredientToCalculate !== undefined && Object.entries(ingredientToCalculate)?.length > 0) {
            fetch(process.env.REACT_APP_API_URL + `/api/dishes/calculate_info`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ingredientsForDishes: updatedIngredientsToCalculate})

            }).then(response => {
                return response.json()
            }).then(data => {
                setCalculatedNutritional(data)
            })
        }
    }, 1000, [updatedIngredientsToCalculate,ingredientToCalculate])

    useEffect(() => {
        props.getCalculatedNutritional(calculatedNutritional)
    }, [calculatedNutritional])

    return <ArrayInput {...props} source="ingredientsForDishes" label={""}>
        <SimpleFormIterator removeButton={<DefaultRemoveButton setOpen={props.setOpenNutrition}/>}>
            <AutocompleteInput
                onFocus={(e) => {
                    props.setOpenNutrition(true)
                    setIngredientIndex(parseInt(e.target.name.split(".")[0].replace(/^[^0-9]+/, ''), 10))
                    setIngredientToCalculate(prev => {
                        if (Object.entries(prev).length === 0 && parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, '') < props.ingredients.length)) {
                            return props.ingredients[parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, ''), 10)]
                        } else if (ingredientToCalculate !== undefined) {
                            return ingredientToCalculate;
                        } else return {}
                    })
                }
                }
                onChange={(e) => {
                    setIngredientToCalculate(prev => {
                        if (prev === undefined) {
                            return ingredientToCalculate
                        } else {
                            return {...prev, ingredient: e}
                        }
                    })
                }}
                label={"Produkty"} source="ingredient" choices={choices(data)}/>
            <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>
            <NumberInput
                onChange={(e) => {
                    setIngredientToCalculate(prev => {
                        if (Object.entries(prev).length === 0) {
                            setIngredientIndex(parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, '')));
                            return {...props.ingredients[parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, ''), 10)], grams: +e.target.value}
                        } else if (ingredientToCalculate !== undefined) {
                            return {...prev, grams: +e.target.value};
                        } else return null

                    })
                }}
                validate={validateGrams} defaultValue={0} required={true} source={"grams"}
                         label={"Ilość gram"}/>
            <NumberInput
                onChange={(e) => {
                    setIngredientToCalculate(prev => {
                        return {...prev, portionOfSauce: +e.target.value}
                    })
                }}
                source={"portionOfSauce"} label={"Porcja"} defaultValue={0} validate={validateValue}/>
            <OrderButtons onChange={input.onChange} ingredientsForDishes={input.value || []}/>
        </SimpleFormIterator>
    </ArrayInput>
}

const IngredientNames = (props) => {
    const {data, ids, total, loading, loaded, error, refetch} = useGetList(
        'ingredients',
        {page: 1, perPage: 500}
    );
    const record = useRecordContext()
    const getNames = Object.values(data).map(e => {
        if (e.id === record.ingredient) {
            return <span>{e.title}</span>
        } else return <></>
    })
    return getNames;
}
export default MealEdit;