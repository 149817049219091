import React, {useEffect, useState} from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    Filter, SearchInput, ReferenceField
} from 'react-admin';
import {useIntrospection} from "@api-platform/admin";
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const UserList = (props) => {
    // const UserFilter = (props) => (
    //     <Filter {...props}>
    //         <SearchInput source="name" resettable alwaysOn/>
    //     </Filter>
    // )


    return <List
        exporter={false}
        // filters={<UserFilter />}
        {...props}>
        <Datagrid>
            <TextField source="name" label="Imię" />
            <TextField source='surname' label="Nazwisko"/>
            <EmailField source='email' label="Email"/>

            <ReferenceField label="Grupa" source="group" reference="groups">
                <TextField source="title" />
            </ReferenceField>

            <BooleanField label={'Dostęp'} source="isActive" />
            <EditButton basePath='/users' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/users' label="Usuń" />
        </Datagrid>
    </List>
}
export default UserList;


