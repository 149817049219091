import React, {cloneElement, useEffect, useState} from "react";
import RichTextInput from "ra-input-rich-text";
import {CircularProgress} from "@material-ui/core";
import {
    AutocompleteArrayInput,
    AutocompleteInput, Button,
    EditContextProvider, FunctionField,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useEditController, useGetList
} from 'react-admin';
import {useField} from "react-final-form";
import axios from "axios";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";


const mapIdToName = (id) => {
    return fields.find(field => id.includes(field.target))?.name
}

const fields = [
    {
        name: 'exercisesGroupOne',
        target: 'workout_exercise_group_as'
    },
    {
        name: 'exercisesGroupTwo',
        target: 'workout_exercise_group_bs'
    },
    {
        name: 'exercisesGroupThree',
        target: 'workout_exercise_group_cs'
    },
    {
        name: 'exercisesGroupFour',
        target: 'workout_exercise_group_ds'
    },
]


const CustomEdit = props => {
    const [exercisesAll, setExercisesAll] = useState([])
    const [exercises, setExercises] = useState({
        exercisesGroupOne: [],
        exercisesGroupTwo: [],
        exercisesGroupThree: [],
        exercisesGroupFour: []
    })
    const dataProvider = useDataProvider();
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(async () => {

        const d = exercises
        const tasks = []

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]

            if (record && record[field.name]) {
                // const records = await


                tasks.push(dataProvider.getMany(field.target, {
                    ids: record[field.name]
                }))

                //d[field.name] = records.data.sort((a,b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish)
            }
        }

        Promise.all(tasks).then(ex => {
            setExercisesAll(ex.map(d => d?.data))
            setExercises({
                exercisesGroupOne: ex[0]?.data.sort((a, b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupTwo: ex[1]?.data.sort((a, b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupThree: ex[2]?.data.sort((a, b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupFour: ex[3]?.data.sort((a, b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise)
            })
        })

    }, [record?.exercisesGroupOne, record?.exercisesGroupTwo, record?.exercisesGroupThree, record?.exercisesGroupFour])

    if (!record) return <CircularProgress/>

    const newRecord = {
        ...controllerProps.record,
        ...exercises,
        exercises: exercisesAll
        // ingredientsForDishes: ingredients?.sort((a,b) => a.ingredientDishOrder - b.ingredientDishOrder),
    }

    return <EditContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </EditContextProvider>
}

const ExercisesArray = ({name, index, record, ...props}) => {
    const dataProvider = useDataProvider();
    const {input} = useField(name)


    const exercise = (record.value || [])?.map(v => props?.choices?.find(e => e?.id === v))
    const handlePosition = (pos, i) => (e) => {
        const el = record.value[i]
        const swapEl = record.value[i + pos]

        Promise.all([dataProvider.update(fields.find(f => f.name === name)?.target, {
            id: el,
            data: {workoutExerciseOrder: i + pos}
        }), dataProvider.update(fields.find(f => f.name === name)?.target, {
            id: swapEl,
            data: {workoutExerciseOrder: i}
        })]).then(data => {
            const copy = [...record.value]
            const elCopy = copy[i]
            copy[i] = copy[i + pos]
            copy[i + pos] = elCopy
            input.onChange(copy)
        })
    }

    return (
        <>
            {input?.value.length > 0 &&
            <div style={{padding: "20px", backgroundColor: "#E4E4e4", borderRadius: "5px"}}>

                <table style={{borderCollapse: "collapse"}}>
                    <tr>
                        <th style={{border: '1px solid black', padding: "5px", backgroundColor: "#fff"}}>Kolejność</th>
                        <th style={{border: '1px solid black', padding: "5px", backgroundColor: "#fff"}}>Nazwa
                            ćwiczenia
                        </th>
                    </tr>
                    {input.value.length > 0 && exercise.map((e, i) => {
                        return (
                            <tr>
                                <td style={{
                                    border: '1px solid black',
                                    padding: "10px",
                                    textAlign: "center",
                                    backgroundColor: "#fff"
                                }}>{i + 1}</td>
                                <td style={{border: '1px solid black', padding: "10px", backgroundColor: "#fff"}}>
                                    <p> {e?.name}</p>
                                    <div className={"flex mt-4 justify-between"} style={{minWidth: "256px"}}>
                                        {i !== 0 && <Button children={<ArrowUpward/>} onClick={handlePosition(-1, i)}
                                                            className={"MuiButtonBase-root MuiButton-root MuiButton-contained RaButton-button-6 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall"}
                                                            label={"Do góry"}/>}
                                        {i !== (exercise.length - 1) &&
                                        <Button children={<ArrowDownward/>} onClick={handlePosition(1, i)}
                                                label={"Na dół"}/>}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>}

        </>
    )
}

const Wrapper = (props) => {

    return <>
        <AutocompleteArrayInput optionText={(item) => `${item?.name} - ${item?.exerciseCode}`}
                                classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} {...props}/>
        <ExercisesArray record={props.input} index={props.index} name={props.name} {...props}/>
    </>
}

const TrainingEdit = (props) => {
    const dataProvider = useDataProvider()

    const transform = async data => {

        const tasks = []

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]

            if (data[field.name] && data[field.name].length > 0) {
                for (const ifd of data[field.name]) {

                    tasks.push(
                        dataProvider.create(field.target, {
                            data: {
                                workout: data.id,
                                exercise: ifd,
                                workoutExerciseOrder: data[field.name].indexOf(ifd) + 1,
                            }
                        })
                    )
                }
            }

        }

        const exercises = await Promise.all(tasks)

        data = {
            ...data,
            exercisesGroupOne: [],
            exercisesGroupTwo: [],
            exercisesGroupThree: [],
            exercisesGroupFour: []
        }

        exercises.forEach(d => {
            data[mapIdToName(d.data.id)].push(d.data.id)
        })

        return data;
    };

    return (
        <CustomEdit title='Edytuj trening'  {...props} transform={transform}>
            <SimpleForm redirect={"/workouts"}>
                <TextInput source='workoutName' label={'Nazwa treningu'}/>
                <TextInput source={"workoutCode"} label={"Kod treningu"}/>
                <ReferenceInput filterToQuery={() => ({pagination: false})} source={"workoutTemplate"}
                                reference={"workout_templates"} label={"Szablon treningu"}>
                    <AutocompleteInput optionText="templateName"/>
                </ReferenceInput>
                <TextInput source='workoutNote' label={'Notatka'}/>
                <RichTextInput source="introductionDescription" label="Strona wstępu"/>
                <RichTextInput source="descriptivePages" label="Strony opisowe"/>

                <span style={{fontWeight: "bold"}}>Trening A</span>
                <div className={"flex"} style={{width: "80%"}}>
                    <ReferenceArrayInput filterToQuery={() => ({pagination: false})} source="exercisesGroupOne"
                                         reference="exercises" label="Trening A - ćwiczenia">
                        <Wrapper index={0} name={'exercisesGroupOne'}/>
                    </ReferenceArrayInput>
                </div>
                <RichTextInput source={"exerciseGroupADescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening B</span>
                <div className={"flex"} style={{width: "60%"}}>
                    <ReferenceArrayInput filterToQuery={() => ({pagination: false})} source="exercisesGroupTwo"
                                         reference="exercises" label="Trening B - ćwiczenia">
                        <Wrapper index={1} name={'exercisesGroupTwo'}/>
                    </ReferenceArrayInput>
                </div>
                <RichTextInput source={"exerciseGroupBDescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening C</span>
                <div className={"flex"} style={{width: "60%"}}>
                    <ReferenceArrayInput filterToQuery={() => ({pagination: false})} source="exercisesGroupThree"
                                         reference="exercises" label="Trening C - ćwiczenia">
                        <Wrapper index={2} name={'exercisesGroupThree'}/>
                    </ReferenceArrayInput>
                </div>
                <RichTextInput source={"exerciseGroupCDescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening D</span>
                <div className={"flex"} style={{width: "60%"}}>
                    <ReferenceArrayInput filterToQuery={() => ({pagination: false})} source="exercisesGroupFour"
                                         reference="exercises" label="Trening D - ćwiczenia">
                        <Wrapper index={3} name={'exercisesGroupFour'}/>
                    </ReferenceArrayInput>
                </div>
                <RichTextInput source={"exerciseGroupDDescription"} label={"Opis treningu"}/>

            </SimpleForm>
        </CustomEdit>
    )
}

export default TrainingEdit;