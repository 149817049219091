import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    RichTextField, Filter, SearchInput, SingleFieldList, ReferenceArrayField, ChipField, TextInput, CloneButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const TrainingFilter = [
        <TextInput label={'Nazwa treningu'} source="workoutName" resettable alwaysOn/>,
        <TextInput label={"Kod treningu"} source="workoutCode" resettable alwaysOn/>,
]

 const TrainingList = (props) => {

    return <List filters={TrainingFilter} {...props} exporter={false}>
        <Datagrid>
            <TextField source='workoutName' label={'Nazwa treningu'} />
            <TextField source={"workoutCode"} label={"Kod treningu"}/>
            <ReferenceArrayField label="Lista ćwiczeń" reference="exercises" source="exercises">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <RichTextField source="descriptivePages" label="Strony opisowe"/>
            <EditButton basePath='/workouts' />
            <CloneButton basePath='/workouts' />
            <DeleteButtonWithConfirmation basePath='/workouts' />
        </Datagrid>
    </List>
}

export default TrainingList;