import React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, RichTextField, EditButton, DeleteButton, NumberField, ReferenceField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';


const CouponListEdit = (props) => {
    return (
        <Edit undoable={false} title='Edytuj kupon'  {...props}>
            <SimpleForm undoable={false} redirect={"/discount_coupons"}>
                <TextInput source='code' label="Numer kuponu"/>
                <span>Cena podana w groszach</span>
                <NumberInput source='amountOfDiscount' label="Kwota zniżki"/>
                <NumberInput source='limitOfUsage' label="Limit użyć"/>
                {/* <ReferenceField link={'xDD'} source="adminOwner" reference="users" label="Osoba, która wygenrowała kupon">
                    <SelectArrayInput optionText="name" />
                </ReferenceField> */}
                <SelectInput source="status" choices={[
                    {id: 0, name: 'Aktywny'},
                    {id: 1, name: 'Nieaktywny'},
                ]}/>

                {/*<EditButton basePath='/coupon' label="Edytuj"/>*/}
                {/*<DeleteButton basePath='/coupon' label="Usuń" />*/}
            </SimpleForm>

        </Edit>
    )
}

export default CouponListEdit;