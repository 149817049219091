import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput, ReferenceArrayField, ReferenceField
} from 'react-admin';
import CustomArrayImageField, {CustomImageField} from "../Fields/CustomArrayImageField";


const MusclesCreate = (props) => {
    return (
        <Create title='Stwórz nową partię mięśni...'  {...props}>
            <SimpleForm redirect={'/muscles'}>
                <TextInput source="muscleName" label="Partia mięśni" />
                <TextInput source="muscleDescription" label="Opis" />
                <ReferenceArrayInput label="Ćwiczenia" reference="exercises" source="exercises">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <ReferenceInput label={"Zdjęcie"} source="photo" reference="attachments">
                    <CustomImageField name={'photo'} />
                </ReferenceInput>
            </SimpleForm>

        </Create>
    )
}

export default MusclesCreate;