import React, {useEffect, useState, cloneElement, useMemo, useCallback} from 'react';
import ButtonRemove from '@material-ui/core/Button';
import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    Button,
    Create,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    useCreateController,
    useDataProvider,
    useGetList,
    CreateContextProvider, useRecordContext, useNotify, minValue, required, minLength, maxLength
} from 'react-admin';
import {CustomImageField} from "../Fields/CustomArrayImageField";
import {Save} from "@material-ui/icons";
import {useFormState} from "react-final-form";
import {useHistory} from "react-router";
import {spanStyle} from "../DietMaker/DietMakerCreate";
import RichTextInput from "ra-input-rich-text";
import {pick} from "lodash/object";
import {isEmpty} from "lodash/lang";
import {CircularProgress} from "@material-ui/core";
import {object} from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
import {CardUl} from "../Meals/MealEdit";
import styled from "styled-components";
import {useTranslate} from "ra-core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import useDebouncedEffect from "use-debounced-effect";


export const GoBackToolbar = (props) => {
    const history = useHistory();
    const {values, ...rest} = useFormState();
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []
    const {data, ids, loading, error} = useGetList(
        'dishes',
        {page: 1, perPage: 100},
    );
    const [isCodeInBase, setIsCodeInBase] = useState(false)


    useEffect(() => {
        Object.values(data).forEach(e => {

            if (e.dishCode === values.dishCode) {
                setIsCodeInBase(true)

            }
            return isCodeInBase;
        })
    }, [])

    const save = () => {

        delete values['orginId']
        delete values['@id']

        values?.ingredientsForDishes?.forEach((ingr, index) => {
            tasks.push(dataProvider.create('ingredient_dishes', {
                data: {
                    dish: null,
                    grams: ingr.grams,
                    ingredientDishOrder: index + 1,
                    ingredient: ingr.ingredient,
                    portionOfSauce: ingr.portionOfSauce,
                }
            }))

        })

        Promise.all(tasks).then(ingr => {
            try {
                dataProvider.create('dishes', {
                    data: {
                        ...values,
                        ingredientsForDishes: ingr.map(s => s.data.id)
                    }
                }).then((r) => {
                    onSuccess()
                }).catch((err) => {
                    if (values.recipe === undefined) {
                        notify('Pole "Przepis na potrawę" jest wymagane', 'warning')
                    } else {
                        notify('Kod potrawy już istanieje', 'warning')
                    }
                })
            } catch (e) {
                notify('Ten kod potrawy już istnieje!', 'warning')
            }

        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}
                    disabled={values.title === undefined || values.dishCode === undefined}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};


export const validateGrams = [required()]

const CustomCreate = props => {
    const [ingredients, setIngredients] = useState(null)
    const dataProvider = useDataProvider();
    const createControllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;

    useEffect(() => {
        props.getRecord(record)
    }, [])

    useEffect(async () => {
        if (record.ingredientsForDishes) {
            let arr = []
            for (const ingredient of record.ingredientsForDishes) {
                const record = await dataProvider.getOne('ingredient_dishes', {
                    id: ingredient
                })
                if (record.data) {
                    arr.push(pick(record.data, ['grams', 'ingredient', 'dish', 'portionOfSauce', 'ingredientDishOrder']));
                }
            }

            setIngredients(arr)
        }
    }, [record.ingredientsForDishes])

    if (!record) return <CircularProgress/>

    const newRecord = {
        ...createControllerProps.record,
        ingredientsForDishes: ingredients?.sort((a, b) => a.ingredientDishOrder - b.ingredientDishOrder),
        id: null,
    }
    props.onGetIngredients(ingredients)
    return <CreateContextProvider value={{
        ...createControllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </CreateContextProvider>
}

const validateUserCreation = (values) => {
    console.log(values)
};

const MealCreate = (props) => {
    const dataProvider = useDataProvider();
    const [newRecord, setNewRecord] = useState({});
    const [ingredients, setIngredients] = useState([]);
    const [openNutrition, setOpenNutrition] = useState(true)
    const {data, ids, total, loading, loaded, error, refetch} = useGetList(
        'ingredients',
        {page: 1, perPage: 500}
    );

    const getRecord = (record) => {
        setNewRecord(record)
    }

    const getIngredients = (record) => {
        setIngredients(record)
    }

    const required = (message = 'Wymagane') =>
        value => value ? undefined : message;

    const validate = [required()]

    // const validateUserCreation = (values) => {
    //     const errors = [];
    //     Object.values(xxx.data).map((e)=> {
    //         if (e.title === values.title) {
    //             errors.push("Nazwa jest już w bazie")
    //             console.log("kurczas")
    //         }
    //     })
    //     return errors;

    // if (!values.firstName) {
    //     errors.firstName = 'The firstName is required';
    // }
    // if (!values.age) {
    //     // You can return translation keys
    //     errors.age = 'ra.validation.required';
    // } else if (values.age < 18) {
    //     // Or an object if the translation messages need parameters
    //     errors.age = {
    //         message: 'ra.validation.minValue',
    //         args: { min: 18 }
    //     };
    // }

    // };

    const choices = (el) => {
        return Object.values(el).map((e) => ({
            id: e.id,
            name: e.title
        }))
    }

    const validateValue = [minValue(0, "Wartość musi być dodatnia"),];
    const [ingredientToCalculate, setIngredientToCalculate] = useState({});
    const [selectedIngredient, setSelectedIngredient] = useState(null)
    const [ingredientArray, setIngredientsArray] = useState([])
    const [calculatedNutritional, setCalculatedNutritional] = useState({});
    const [updatedIngredientsToCalculate, setUpdatedIngredientsToCalculate] = useState([]);
    const [newArr, setNewArr] = useState([])
    const [ingredientIndex, setIngredientIndex] = useState(null)

    const validateRecipe = [required(), minLength(2), maxLength(15)];

    useEffect(() => {
        if (ingredients !== null && ingredients.length > 0) {
            setUpdatedIngredientsToCalculate(ingredients)
        }
    }, [ingredients])

    useEffect(() => {
        if (Object.entries(ingredientToCalculate).length > 0 && updatedIngredientsToCalculate?.length === 0) {
            return setUpdatedIngredientsToCalculate([{...ingredientToCalculate, grams: 0}])
        }

        // setUpdatedIngredientsToCalculate(prev=> {
        //     if (prev?.length > 0) {
        //         return [...prev, ingredientToCalculate]
        //     }
        // })
        updatedIngredientsToCalculate?.map(ingredient => {
            if (ingredient?.id === ingredientToCalculate?.id) {
                setUpdatedIngredientsToCalculate(prev => {
                    if (Object.entries(prev).length === 0) {
                        return props.ingredients
                    } else {
                        const prevCopy = [...prev]
                        let isInArray = false
                        prevCopy.forEach((value, index) => {
                            if (index === ingredientIndex) {
                                prevCopy[index] = ingredientToCalculate;
                                isInArray = true
                            }
                        })
                        if (!isInArray) {
                            prevCopy.push(ingredientToCalculate);
                        }
                        return [...prevCopy];
                    }
                })
            }
        })
        if (updatedIngredientsToCalculate?.every(a => a.id !== ingredientToCalculate.id) && updatedIngredientsToCalculate.length !== 0) {
            setUpdatedIngredientsToCalculate([...updatedIngredientsToCalculate, ingredientToCalculate])
        }

    }, [ingredientToCalculate])

    const DefaultRemoveButton = props => {

        const translate = useTranslate();

        return (
            <ButtonRemove size="small"  {...props} onClick={(e) => {
                ingredientArray.splice(props.className.replace(/^[^0-9]+/, ''), 1)
                const newIngredientArray = [...ingredientArray]
                setNewArr(newIngredientArray)
                props.onClick(e)
                if (ingredientArray.length === 0) {
                    setCalculatedNutritional(prev => {
                        return {
                            ...prev,
                            calculatedDishCalorific: 0,
                            calculatedDishCarbohydrates: 0,
                            calculatedDishProteins: 0,
                            calculatedDishFats: 0,
                            calculatedDishPrice: 0
                        }
                    })
                    setOpenNutrition(false)
                }
            }}>
                <CloseIcon/>
                {translate('ra.action.remove')}
            </ButtonRemove>
        );
    };

    useEffect(() => {
        if (newArr.length > 0) {
            setIngredientsArray(newArr)
        }
    }, [newArr])

    useDebouncedEffect(() => {
        if (ingredientToCalculate !== undefined && Object.entries(ingredientToCalculate)?.length > 0) {
            fetch(process.env.REACT_APP_API_URL + `/api/dishes/calculate_info`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ingredientsForDishes: updatedIngredientsToCalculate})

            }).then(response => {
                return response.json()
            }).then(data => {
                setCalculatedNutritional(data)
            })
        }
    }, 1000, [updatedIngredientsToCalculate, ingredientToCalculate])


    return (
        <CustomCreate title='Stwórz posiłek'  {...props} getRecord={getRecord}
                      onGetIngredients={getIngredients}>
            <SimpleForm redirect={'/dishes'} toolbar={<GoBackToolbar/>}>
                <TextInput multiline source="title" label="Nazwa potrawy" required validate={validate}/>
                <TextInput source="dishCode" label="Kod potrawy" validate={validate} required/>
                <ReferenceInput label={"Zdjęcia potrawy"} source="photo" reference="attachments">
                    <CustomImageField name={'photo'}/>
                </ReferenceInput>

                <ReferenceArrayInput label="Kategoria" source="categories" reference="dish_categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>


                {/*<SelectArrayInput label="Ilość posiłków w diecie" source="categories" choices={[*/}
                {/*    { id: '1', name: '1' },*/}
                {/*    { id: '2', name: '2' },*/}
                {/*]} />*/}


                {/*<AutocompleteArrayInput label={"Ilość kalorii w diecie"} source="tags" choices={caloriesChoices} />*/}


                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <SelectArrayInput optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                <RichTextInput multiline source="recipe" label="Przepis na potrawę*" required/>

                <span style={spanStyle}>Dodaj produkt</span>

                <ArrayInput source="ingredientsForDishes" label={""}>
                    <SimpleFormIterator removeButton={<DefaultRemoveButton/>}>
                        <AutocompleteInput label={"Produkty"} source="ingredient" choices={choices(data)}
                                           onFocus={(e) => {
                                               setOpenNutrition(true)
                                               setIngredientIndex(parseInt(e.target.name.split(".")[0].replace(/^[^0-9]+/, ''), 10))
                                               setIngredientToCalculate(prev => {
                                                   if (Object?.entries(prev)?.length === 0 && parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, '') < ingredients?.length)) {
                                                       return ingredients[parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, ''), 10)]
                                                   } else if (ingredientToCalculate !== undefined) {
                                                       return ingredientToCalculate;
                                                   } else return {}
                                               })
                                           }}
                                           onChange={(e) => {
                                               setIngredientToCalculate(prev => {
                                                   if (prev === undefined) {
                                                       return ingredientToCalculate
                                                   } else {
                                                       return {...prev, ingredient: e}
                                                   }
                                               })
                                           }}/>
                        <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>
                        <NumberInput validate={validateGrams} defaultValue={0} required={true} source={"grams"}
                                     label={"Ilość gram"}
                                     onChange={(e) => {
                                         setIngredientToCalculate(prev => {
                                             if (Object.entries(prev).length === 0) {
                                                 setIngredientIndex(parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, '')));
                                                 return {
                                                     ...ingredients[parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, ''), 10)],
                                                     grams: +e.target.value
                                                 }
                                             } else if (ingredientToCalculate !== undefined) {
                                                 if (updatedIngredientsToCalculate.length > 0) {
                                                     setIngredientIndex(parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, '')));
                                                     const existingIngredient = updatedIngredientsToCalculate[parseInt(e.target.id.split(".")[0].replace(/^[^0-9]+/, ''))];
                                                     const updatedIngredient = {
                                                         ...existingIngredient,
                                                         grams: +e.target.value
                                                     }
                                                     return updatedIngredient;
                                                 } else return {...prev, grams: +e.target.value};
                                             } else return null;
                                         })
                                     }}/>
                        <NumberInput source={"portionOfSauce"}
                                     label={"Porcja"}
                                     defaultValue={0}
                                     validate={validateValue}
                                     onChange={(e) => {
                                         setIngredientToCalculate(prev => {
                                             return {...prev, portionOfSauce: +e.target.value}
                                         })
                                     }}/>
                    </SimpleFormIterator>
                </ArrayInput>
                {openNutrition && calculatedNutritional && (ingredients?.length > 0 || updatedIngredientsToCalculate.length > 0) &&
                    <CardUl>
                        <li>
                            <div>Tłuszcze</div>
                            <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishFats : newRecord.calculatedDishFats} g</div>
                        </li>
                        <li>
                            <div>Węglowodany</div>
                            <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishCarbohydrates : newRecord.calculatedDishCarbohydrates} g</div>
                        </li>
                        <li>
                            <div>Białko</div>
                            <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishProteins : newRecord.calculatedDishProteins} g</div>
                        </li>
                        <li>
                            <div>Ilość kalorii</div>
                            <div>{Object?.entries(calculatedNutritional).length > 0 ? calculatedNutritional?.calculatedDishCalorific : newRecord.calculatedDishCalorific} Kcal</div>
                        </li>
                        <button onClick={() => {
                            setOpenNutrition(false)
                        }
                        }><CancelIcon/></button>
                    </CardUl>}


                {/*<ProductCreateButton/>*/}
                {/*<ReferenceArrayField*/}
                {/*    label="Produkty"*/}
                {/*    reference="ingredients"*/}
                {/*    source="ingredientsForDishes"*/}
                {/*>*/}
                {/*    <Datagrid>*/}
                {/*        <IngredientNames/>*/}
                {/*        <DeleteButton undoable={false} redirect={false}/>*/}
                {/*    </Datagrid>*/}
                {/*</ReferenceArrayField>*/}

                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <FilteredSelectField  optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                <NumberInput source='approximatePrepareTime' label="Czas przygotowywania"/>
            </SimpleForm>

        </CustomCreate>

    )
}

export default MealCreate;
