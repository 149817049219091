import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    ReferenceArrayField,
    Datagrid,
    EditButton,
    useRecordContext, useGetList, SingleFieldList, ChipField,
} from 'react-admin';
import {Button} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {useHistory} from "react-router";


const ProductEdit = (props) => {
    const {data: dataSlides} = useGetList('ingredients')
    return (
        <Edit title='Edytuj potrawę'  {...props}>
            <SimpleForm redirect={'/ingredients'}>
                <TextInput source="title" label="Nazwa"/>
                <NumberInput source='value' label="Wartość produktu"/>
                <NumberInput source='fat' label="Tłuszcze na 100g"/>
                <NumberInput source='carbohydrate' label="Węglowodany na 100g"/>
                <NumberInput source='protein' label="Białko na 100g"/>
                <span>Cena podana w groszach</span>
                <NumberInput source='price' label="Cena produktu na 100g"/>
                <ReferenceArrayInput label="Kategoria zamienników" source="categories"
                                     reference="ingredient_categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    )
}

const Label = props => {
    const record = useRecordContext()
    return <span>{record}</span>;
}


export default ProductEdit;