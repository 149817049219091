import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    getFieldLabelTranslationArgs,
    InputProps,
    useReferenceArrayInputController,
    useInput,
    useTranslate,
    SortPayload,
    PaginationPayload,
    Translate,
    ResourceContextProvider,
    ReferenceArrayInputContextProvider,
    ListContextProvider,
} from 'ra-core';

import { FieldInputProps, FieldMetaState } from 'react-final-form';
import {sanitizeInputRestProps} from "react-admin";



const CustomReferenceArrayInput = ({
                                 children,
                                 id: idOverride,
                                 onBlur,
                                 onChange,
                                 onFocus,
                                 validate,
                                 parse,
                                 format,
                                 ...props
                             }) => {
    if (React.Children.count(children) !== 1) {
        throw new Error(
            '<ReferenceArrayInput> only accepts a single child (like <Datagrid>)'
        );
    }

    const { id, input, isRequired, meta } = useInput({
        id: idOverride,
        onBlur,
        onChange,
        onFocus,
        source: props.source,
        validate,
        parse,
        format,
        ...props,
    });

    const controllerProps = useReferenceArrayInputController({
        ...props,
        input,
    });

    const listContext = useMemo(
        () => ({
            ...controllerProps,
            // ReferenceArrayInput.setSort had a different signature than the one from ListContext.
            // In order to not break backward compatibility, we added this temporary setSortForList in the
            // ReferenceArrayInputContext
            setSort: controllerProps.setSortForList,
        }),
        [controllerProps]
    );

    const translate = useTranslate();


    return (
        <ResourceContextProvider value={props.reference}>
            <ReferenceArrayInputContextProvider value={controllerProps}>
                <ListContextProvider value={listContext}>
                    <ReferenceArrayInputView
                        id={id}
                        input={input}
                        isRequired={isRequired}
                        meta={meta}
                        translate={translate}
                        children={children}
                        {...props}
                        choices={controllerProps.choices}
                        loaded={controllerProps.loaded}
                        loading={controllerProps.loading}
                        setFilter={controllerProps.setFilter}
                        setPagination={controllerProps.setPagination}
                        setSort={controllerProps.setSort}
                    />
                </ListContextProvider>
            </ReferenceArrayInputContextProvider>
        </ResourceContextProvider>
    );
};

CustomReferenceArrayInput.defaultProps = {
    filter: {},
    filterToQuery: searchText => (searchText ? { q: searchText } : {}),
    perPage: 25,
    sort: { field: 'id', order: 'DESC' },
};

const sanitizeRestProps = ({
                               basePath,
                               crudGetMany,
                               crudGetMatching,
                               filterToQuery,
                               perPage,
                               reference,
                               referenceSource,
                               resource,
                               ...rest
                           }) => sanitizeInputRestProps(rest);



export const ReferenceArrayInputView = ({
                                            allowEmpty,
                                            basePath,
                                            children,
                                            choices,
                                            className,
                                            error,
                                            input,
                                            loaded,
                                            loading,
                                            isRequired,
                                            label,
                                            meta,
                                            onChange,
                                            options,
                                            reference,
                                            resource,
                                            setFilter,
                                            setPagination,
                                            setSort,
                                            source,
                                            translate,
                                            warning,
                                            ...rest
                                        }) => {
    const translatedLabel = translate(
        ...getFieldLabelTranslationArgs({
            label,
            resource,
            source,
        })
    );


    return React.cloneElement(children, {
        allowEmpty,
        basePath: basePath
            ? basePath.replace(resource, reference)
            : `/${reference}`,
        choices,
        className,
        error,
        input,
        isRequired,
        label: translatedLabel,
        loaded,
        loading,
        meta: {
            ...meta,
            helperText: warning || false,
        },
        onChange,
        options,
        resource: reference,
        setFilter,
        setPagination,
        setSort,
        source,
        translateChoice: false,
        limitChoicesToValue: true,
        ...sanitizeRestProps(rest),
        ...children.props,
    });
};


export default CustomReferenceArrayInput;
