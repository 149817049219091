import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField, ReferenceField, SingleFieldList, ReferenceArrayField, ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

 const MuscleList = (props) => {
    return <List {...props} exporter={false}>
        <Datagrid>
            <TextField source="muscleName" label="Partia mięśni" />
            <ReferenceArrayField label="Ćwiczenia" reference="exercises" source="exercises">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <NumberField source='exercisesCount' label="Ilość ćwiczeń"/>

            <EditButton basePath='/muscles' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/muscles' label="Usuń" />
        </Datagrid>
    </List>
}

export default MuscleList;