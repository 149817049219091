import React, {useEffect, useState} from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    FunctionField,
    SelectField,
    RichTextField, ReferenceField, TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const filters = [
    <TextInput label="Id klienta" source="client.helperId" alwaysOn />,
];

 const ClientImagesList = (props) => {

    return <List  {...props} filters={filters} exporter={false} >
        <Datagrid>

            {/*<TextField source='name' label="Imię i nazwisko" />*/}

            <ReferenceField label="Klient" source="client" reference="clients">
                <TextField label={"Imię"} source={"name"}/>
            </ReferenceField>
            <TextField label={"Nazwa zdjęć"} source={"title"}/>
            <DateField disabled={true} label={"Data zdjęć"} source={"photosDate"} />
            <DateField disabled={true} label={"Data utworzenia"} source={"createdAt"} />
            <NumberField source='photosCount' label="Liczba zdjęć" />

            <EditButton basePath='/silhouettes_photos' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/silhouettes_photos' label="Usuń" />
        </Datagrid>
    </List>
}

export default ClientImagesList;