import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    ReferenceField,
    FunctionField, SingleFieldList, ReferenceArrayField, TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const filters = [
    <TextInput label="Id klienta" source="client.helperId" alwaysOn/>,
];

 const MeasureList = (props) => {
    return <List {...props} filters={filters} exporter={false}>
        <Datagrid>
            <ReferenceField label="Imie i nazwisko" reference="clients" source="client">
                    <FunctionField render={record => `${record.name} ${record.surname}`} />
            </ReferenceField>
            <DateField source='createdAt' label="Data dodania"/>
            <DateField source='measurementDate' label="Data pomiaru"/>
            <TextField source="title" label="Nazwa pomiaru" />
            <NumberField source='clientBodyWeight' label="Masa ciała"/>
            <NumberField source='clientChestSize' label="Obwód klatki pier."/>
            <NumberField source='clientWaistSize' label="Obwód pasa"/>
            <NumberField source='clientHipSize' label="Obwód bioder"/>
            <NumberField source='clientThighSize' label="Obwód uda"/>
            <NumberField source='clientArmSize' label="Obwód ramienia"/>

            <EditButton basePath='/measurement_forms' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/measurement_forms' label="Usuń" />
        </Datagrid>
    </List>
}

export default MeasureList;