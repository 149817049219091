import React, {useEffect, useState} from 'react'
import {
    Show,
    SimpleShowLayout,
    Datagrid,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    ShowButton,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextField,
    TopToolbar,
    EditButton,
    Button,
    useDataProvider,
    useGetList
} from "react-admin";
import {Link, NavLink} from "react-router-dom";
import {ChevronLeft, ChevronRight, Create} from "@material-ui/icons";
import {stringify} from 'query-string';
import {ChatButton} from "../OrderList/OrderShow";

const PostShowActions = ({basePath, data, resource}) => {
    const dataProvider = useDataProvider()
    const [questionaries, setQuestionaries] = useState([])

    const [client, setClient] = useState(null)

    useEffect(() => {
        if (data?.owner) {
            dataProvider.getOne('clients', {id: data?.owner}).then(response => {
                setClient(response.data)
            })
        }
    }, [data?.owner])

    useEffect(() => {
        if (client?.helperId) {
            dataProvider.getList('questionnaires', {
                pagination: {page: 1, perPage: 10000},
                sort: {},
                filter: {
                    "owner.helperId": client?.helperId
                }
            }).then(response => {
                setQuestionaries(response.data)
            })
        }

    }, [client])

    return (
        <TopToolbar>

            <Button
                component={Link}
                to={{
                    pathname: '/purchases/' + encodeURIComponent(data?.purchase),
                }}
                label={'Przypisz dietę i trening'}
            >
                <Create/>
            </Button>

            {questionaries?.find(q => (new Date(q?.createdAt) > new Date(data?.createdAt )) && data.id !== q.id) && <Button
                component={Link}
                to={{
                    pathname: '/questionnaires/' + encodeURIComponent(questionaries?.find(q => (new Date(q?.createdAt ) > new Date(data?.createdAt )) && data.id !== q.id)?.id) + '/show',
                }}
                label={'Następna'}
            >
                <ChevronLeft/>
            </Button>}

            {questionaries?.find(q => (new Date(q?.createdAt) < new Date(data?.createdAt)) && data.id !== q.id) && <Button
                component={Link}
                alignIcon={'right'}
                to={{
                    pathname: '/questionnaires/' + encodeURIComponent(questionaries?.find(q => (new Date(q.createdAt) < new Date(data.createdAt)) && data.id !== q.id)?.id) + '/show',
                }}
                label={'Poprzednia'}
            >
                <ChevronRight/>
            </Button>}
            {/*<EditButton label={'Przypisz dietę i trening'} basePath={'/purchases'} record={data} />*/}
            {/* Add your custom actions */}
        </TopToolbar>
    );
}


const PostTitle = ({record}) => {
    return <span>Ankiety użytkowników {record ? `${record.originId}` : ''}</span>;
};


function QuestionnaireClientShow({...props}) {
    return (
        <div>
            <Show {...props} title={<PostTitle/>} actions={<PostShowActions/>} exporter={false}>
                <SimpleShowLayout>
                    <ReferenceField label="Imie i nazwisko" reference="clients" source="owner">
                        <FunctionField render={record => `${record.name} ${record.surname}`}/>
                    </ReferenceField>
                    <ChatButton source={'owner'} buttons={true}/>
                    <ReferenceField label="Zamówienie" reference="purchases" source="purchase">
                        <FunctionField render={record => `${record.name}`}/>
                    </ReferenceField>
                    <NumberField label="Ilość pytań" source='questionsCount'/>
                    <ReferenceArrayField sort={{
                        "field": "questionOrder",
                        "order": "ASC"
                    }} label="Pytania" reference="questions" source="questions">
                            <MyDataGrid/>
                    </ReferenceArrayField>
                </SimpleShowLayout>
            </Show>
        </div>
    );
};

const MyDataGrid = (props) => {
    return <Datagrid {...props} >

        <TextField label="Pytanie" source="question"/>
        <ReferenceArrayField label="Odpowiedź" reference={'answers'} source={'answers'}>
            <SingleFieldList>
                <TextField source="answer"/>
            </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField label="Kategoria" source="questionCategory" reference="question_categories">
            <TextField source="title"/>
        </ReferenceField>
    </Datagrid>
}

export default QuestionnaireClientShow