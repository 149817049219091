import React from 'react'
import {Labeled, ReferenceField, TextField} from "react-admin";

function DietTrainingField(props) {

 return (
  <div {...props} style={{display: 'flex', flexDirection: 'column'}}>
      <Labeled

          label={'Dieta'}
          disabled={false}
      />
   <ReferenceField {...props} label={'Dieta'} reference={'diets'} source={'diet'}>
     <TextField {...props} label={'Dieta'} source={'dietName'}/>
   </ReferenceField>
      <Labeled

          label={'Ćwiczenia'}
          disabled={false}
      />
   <ReferenceField  {...props} label={'Ćwiczenia'} reference={'workouts'} source={'workout'}>
    <TextField {...props} label={'Ćwiczenia'} source={'workoutName'}/>
   </ReferenceField>
  </div>
 );
};


export default DietTrainingField