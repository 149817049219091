import React, {cloneElement, useEffect, useState} from "react";
import {
    AutocompleteArrayInput,
    Button,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreateController,
    useDataProvider,
    CreateContextProvider,
    useNotify,
    AutocompleteInput, useGetList
} from 'react-admin';
import {useHistory} from "react-router";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";
import {CircularProgress} from "@material-ui/core";
import CustomAutocompleteArrayInput from "../Fields/CustomAutocompleteArrayInput";
import {DishEditDialog} from "./DietMakerEdit";

export const spanStyle = {
    fontSize: "18px",
    fontWeight: "bold"
}

const mapIdToName = (id) => {
    return fields.find(field => id.includes(field.target))?.name
}

const fields = [
    {
        name: 'breakfasts',
        target: 'diet_dish_breakfasts'
    },
    {
        name: 'secondBreakfasts',
        target: 'diet_dish_second_breakfasts'
    },
    {
        name: 'lunches',
        target: 'diet_dish_lunches'
    },
    {
        name: 'secondLunches',
        target: 'diet_dish_second_lunches'
    },
    {
        name: 'dinners',
        target: 'diet_dish_dinners'
    },
    {
        name: 'desserts',
        target: 'diet_dish_desserts'
    },
    {
        name: 'snacks',
        target: 'diet_dish_snacks'
    }]

const GoBackToolbar = (props) => {
    const history = useHistory();
    const {values} = useFormState();
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []

    const save = () => {

        delete values['orginId']
        delete values['@id']


        fields.forEach(field => {

            (values[field.name] || []).forEach((ingr, index) => {

                tasks.push(dataProvider.create(field.target, {
                    data: {
                        diet: null,
                        dish: ingr,
                        dietDishOrder: index + 1,
                    }
                }))

            })

        })

        const dishes = {}

        Promise.all(tasks).then(ingr => {
            try {
                ingr.forEach(i => {
                    if (dishes[mapIdToName(i.data.id)]) {
                        dishes[mapIdToName(i.data.id)].push(i.data.id)
                    } else {
                        dishes[mapIdToName(i.data.id)] = [i.data.id]
                    }
                })


                dataProvider.create('diets', {
                    data: {
                        ...values,
                        ...dishes
                    }
                }).then((r) => {
                    onSuccess()
                }).catch((err) => {
                    notify('Wystąpił błąd', 'warning')
                })
            } catch (e) {
                console.error(e)
                notify('Wystąpił błąd', 'warning')
            }

        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};


const CustomCreate = props => {
    const [dishes, setDishes] = useState({
        breakfasts: [],
        secondBreakfasts: [],
        lunches: [],
        secondLunches: [],
        dinners: [],
        desserts: [],
        snacks: []
    })
    const dataProvider = useDataProvider();
    const controllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(async () => {
        if(record) {
            const d = dishes
            const tasks = []

            for (let i = 0; i < fields.length; i++) {
                const field = fields[i]
                if (record && record[field.name]) {
                    // const records = await


                    tasks.push(dataProvider.getMany(field.target, {
                        ids: record[field.name]
                    }))

                    //d[field.name] = records.data.sort((a,b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish)
                }

            }

            Promise.all(tasks).then(dishes => {
                setDishes({
                    breakfasts: dishes[0]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    secondBreakfasts: dishes[1]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    lunches: dishes[2]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    secondLunches: dishes[3]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    dinners: dishes[4]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    desserts: dishes[5]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish),
                    snacks: dishes[6]?.data.sort((a, b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish)
                })
            })
        }

    }, [])

    if (!record) return <CircularProgress/>

    const newRecord = {
        ...controllerProps.record,
        ...dishes
        // ingredientsForDishes: ingredients?.sort((a,b) => a.ingredientDishOrder - b.ingredientDishOrder),
    }

    return <CreateContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </CreateContextProvider>
}


const DietMakerCreate = (props) => {
    const [open, setOpen] = useState(false)
    const [dish, setDish] = useState(null)
    const [field, setField] = useState('breakfasts')
    const toggleOpen = () => setOpen(!open)

    const editDish = (field) => (item) => {
        setOpen(true)
        setField(field)
        setDish(item)
    }

    const closeModal = () => {
        setDish(null)
        setOpen(false)
    }


    return (
        <CustomCreate title='Stwórz dietę'  {...props} >
            <SimpleForm toolbar={<GoBackToolbar/>} redirect={"/diets"}>
                <DishEditDialog closeModal={closeModal} open={open} setOpen={setOpen} dish={dish} field={field}/>
                <TextInput source='dietName' label={'Nazwa Diety'}/>
                <TextInput source='dietNote' label={'Notatka'}/>
                {/*<RichTextInput source="introductionDescription" label="Strona wstępu"/>*/}

                <ReferenceInput filterToQuery={() => ({
                    pagination: false
                })} source="dietTemplate" reference="diet_templates" label={"Szablon diety"}>
                    <AutocompleteInput optionText="templateName"/>
                </ReferenceInput>

                <span style={spanStyle}>I ŚNIADANIE</span>
                {/*filterToQuery={searchText => (searchText ? { dishCode: searchText} : {}) }*/}
                <ReferenceArrayInput  source="breakfasts" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('breakfasts')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}}  optionText={choice => `${choice?.dishCode} (${choice?.title})`} />
                </ReferenceArrayInput>
                {/*<ArrayInput source="ingredientsForDishes" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                <span style={spanStyle}>II ŚNIADANIE</span>

                <ReferenceArrayInput source="secondBreakfasts" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('secondBreakfasts')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>

                {/*<ArrayInput source="ingredientsForDishes1" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                <span style={spanStyle}>OBIAD</span>
                <ReferenceArrayInput source="lunches" reference="dishes" label="Potrawa"
                                     enableGetChoices={({ q }) => (q ? q.length >= 5 : false)}
                                     filterToQuery={searchText => (searchText? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:false
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('lunches')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>
                {/*<ArrayInput source="ingredientsForDishes2" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}


                <span style={spanStyle}>II OBIAD</span>

                <ReferenceArrayInput source="secondLunches" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('secondLunches')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>
                {/*<ArrayInput source="ingredientsForDishes3" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}


                <span style={spanStyle}>KOLACJA</span>
                <ReferenceArrayInput source="dinners" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('dinners')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>

                {/*<ArrayInput source="ingredientsForDishes4" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                <span style={spanStyle}>DESERY</span>
                <ReferenceArrayInput source="desserts" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('desserts')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>

                {/*<ArrayInput source="ingredientsForDishes5" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="desserts" reference="dishes" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                <span style={spanStyle}>PRZEKĄSKI</span>

                <ReferenceArrayInput source="snacks" reference="dishes" label="Potrawa"
                                     filterToQuery={searchText => (searchText ? {
                                         dishCode: searchText,
                                         title: searchText,
                                         pagination:true
                                     } : {pagination:true})}>
                    <CustomAutocompleteArrayInput toggleModal={editDish('snacks')} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}} optionText={choice => `${choice?.dishCode} (${choice?.title})`}/>
                </ReferenceArrayInput>
                {/*<ArrayInput source="ingredientsForDishes6" label={""} style={{marginBottom: "40px"}}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="snacks" reference="dishes" label="Potrawa">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}


                {/*<ReferenceArrayInput label={'Desery'} source="desserts" reference="dishes">*/}
                {/*    <SelectInputGrouped groups={data} optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                {/*<ReferenceArrayInput label={'Przekąski'} source="snacks" reference="dishes">*/}
                {/*    <SelectInputGrouped groups={data} optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                {/*<ArrayInput label="Dodaj potrawę z kategorii"*/}
                {/*            reference="meals"*/}
                {/*            source="meals">*/}

                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="meals" reference="dish_categories" label="Kategoria potrawy">*/}
                {/*            <SelectInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}

                {/*    </SimpleFormIterator>*/}

                {/*</ArrayInput>*/}

                {/*<ReferenceArrayInput source="meals" reference="dish_categories" label="Kategoria potrawy">*/}
                {/*    <SelectArrayInput optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}
                {/*<RichTextInput source="macro" label="Opis makroskładników"/>*/}
                {/*<RichTextInput source="description" label="Strony opisowe"/>*/}

            </SimpleForm>

        </CustomCreate>
    )
}

export default DietMakerCreate;
