import React, {useEffect} from 'react'
import {useField} from "react-final-form";


function ReferenceIdField({name, source}) {
        const {input} = useField(name)

        useEffect(() => {
            input.onChange(source)
        }, [source])

    return <></>
}


export default ReferenceIdField