import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, DateField, NumberField,
    ReferenceInput
} from 'react-admin';


const MeasureCreate = (props) => {
    return (
        <Create title='Stwórz formularz pomiarowy'  {...props}>
            <SimpleForm redirect={'/measurement_forms'}>
                <ReferenceInput source="client" reference="clients" label="Wybór użytkownika">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateInput disabled source='publishedAt' label="Data dodania" defaultValue={new Date}/>
                <DateInput source='measurementDate' label="Data pomiaru" defaultValue={new Date}/>
                <TextInput source="title" label="Nazwa pomiaru" />
                <NumberInput source='clientBodyWeight' label="Masa ciała"/>
                <NumberInput source='clientChestSize' label="Obwód klatki pier."/>
                <NumberInput source='clientWaistSize' label="Obwód pasa"/>
                <NumberInput source='clientHipSize' label="Obwód bioder"/>
                <NumberInput source='clientThighSize' label="Obwód uda"/>
                <NumberInput source='clientArmSize' label="Obwód ramienia"/>
            </SimpleForm>

        </Create>
    )
}

export default MeasureCreate;