import React from "react";
import {Edit, SimpleForm, TextInput, NumberInput, DateInput, NumberField, SelectInput, BooleanInput} from 'react-admin';


const UserGroupEdit = (props) => {
    return (
        <Edit title='Edycja użytkownika'  {...props}>
            <SimpleForm redirect={'/groups'}>
                <TextInput source={'title'} label='Nazwa grupy'/>
                <BooleanInput label="Użytkownicy" source="isUsersAccess" />
                <BooleanInput label="Grupy użytkowników" source="isGroupsAccess" />
                <BooleanInput label="Klienci" source="isClientsAccess" />
                <BooleanInput label="Dania" source="isDishesAccess" />
                <BooleanInput label="Ankiety" source="isQuestionnaireAccess" />
                <BooleanInput label="Ankiety klientów" source="isClientsQuestionnaireAccess" />
                <BooleanInput label="Zamówienia" source="isPurchasesAccess" />
                <BooleanInput label="Ceny produktów" source="isProductsAccess" />
                <BooleanInput label="Kupony rabatowe" source="isCouponsAccess" />
                <BooleanInput label="Ćwiczenia" source="isExercisesAccess" />
                <BooleanInput label="FAQ" source="isFAQAccess" />
                <BooleanInput label="Wiadomości flash" source="isFlashMessageAccess" />
                <BooleanInput label="Formularze pomiarów" source="isMeasurementFormsAccess" />
                <BooleanInput label="Zdjęcia sylwetki" source="isSilhouettePhotosAccess" />
                <BooleanInput label="Chat" source="isChatAccess" />
            </SimpleForm>

        </Edit>
    )
}

export default UserGroupEdit;