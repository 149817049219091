import React from "react";
import {Create, SimpleForm, TextInput, DateInput, NumberInput, SelectInput, TextField, DateField} from 'react-admin';
import RichTextInput from "ra-input-rich-text";


const FaqCreate = (props) => {
    return (
        <Create title='Utwórz pytanie...'  {...props}>
            <SimpleForm redirect={'/faqs'}>
                <TextInput multiline source="question" label="Pytanie" />
                <SelectInput label={"Kategoria"} source="category" choices={[
                  { id: 1, name: 'Informacje żywieniowe' },
                  { id: 2, name: 'Informacje treningowe' },
                ]} />
                <RichTextInput multiline source="answer" label="Odpowiedź" />
                {/*<DateInput source='editeddAt' label="Data edycji"/>*/}
            </SimpleForm>

        </Create>
    )
}

export default FaqCreate;