import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ReferenceArrayField,
    NumberField,
    EditButton,
    DeleteButton, BooleanField,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import * as PropTypes from "prop-types";

import CountField from "../../components/Fields/CountField";
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";


const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange'}
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};


const QuestionAdd = (props) => {
    return <List {...props} exporter={false}>

                <Datagrid>
                    <TextField label="Pytanie" source={"question"}/>
                    <NumberField label="Kolejność" source={"questionOrder"}/>
                    <ReferenceField label="Kategoria pytań" source="questionCategory" reference="question_categories">
                        <TextField source="title" />
                    </ReferenceField>
                    <CountField label={"Ilość odpowiedzi"} source="answers"/>
                    <BooleanField label="Odpowiedź tekstowa" source={'isOpenQuestion'} looseValue={true}/>
                    <EditButton/>
                    <DeleteButtonWithConfirmation basePath='/questions' label="Usuń"/>
                </Datagrid>
    </List>
}

export default QuestionAdd;