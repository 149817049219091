import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    DateField,
    BooleanField,
    CreateButton,
    ShowButton,
    NumberField,
    EditButton,
    DeleteButton,
    TopToolbar,
    SelectField,
    Show,
    SimpleShowLayout,
    RichTextField,
    ReferenceField,
    FunctionField,
    ChipField,
    SingleFieldList,
    BulkDeleteButton,
    TextInput
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import CheckboxField from "../Fields/CheckboxField";
import {Fragment} from 'react';

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange'}
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const OrderListActions = ({basePath}) => (
    <TopToolbar>

    </TopToolbar>
);

const postFilters = [
    <TextInput label="Id zamówienia" source="helperId" alwaysOn/>,
    <TextInput label="Id klienta" source="client.helperId" alwaysOn/>,
];


export const paymentsStatus = ['Nowa', 'Oczekująca na płatność', 'Oczekująca na ankietę', 'Oczekująca na akcje', 'Oczekująca na aktywacje', 'Aktywny', 'Wygasający', 'Wygasła']

const BulkActionsButtons = ({basePath}) => (
    <Fragment>
        <BulkDeleteButton basePath={basePath}/>
    </Fragment>
)

const styles = {
    image: {
        width: "20px",
        height: "20px"
    }
};

const OrderList = (props) => {

    return <List filters={postFilters} exporter={false} {...props} bulkActionButtons={<BulkActionsButtons/>}>
        <Datagrid>
            <TextField label={'Id'} source='helperId'/>
            <ReferenceField label="Imię i nazwisko" source="client" reference="clients">
                <FunctionField render={record => `${record.name} ${record.surname}`}/>
            </ReferenceField>
            <ReferenceField label="Adres email" source="client" reference="clients">
                <FunctionField render={record => record.notifyEmail? record.notifyEmail: record.email}/>
            </ReferenceField>
            <ReferenceField label="Wykupiony pakiet" source="product" reference="products">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Powiązane zamówienie" source="parentForDuplicatedPurchase" reference="purchases">
                <TextField source="helperId"/>
            </ReferenceField>
            <DateField source="createdAt" showTime label={"Data i godzina złożenia zamówienia"}/>
            {/*<NumberField source='finalPrice' label="Cena" record={record => (*/}
            {/*    {id: record.id,*/}
            {/*    finalPrice: record.finalPrice/100}*/}
            {/*)*/}
            {/*}/>*/}
            <FunctionField source='finalPrice' label="Cena" render={record => `${record.finalPrice / 100} zł`}/>

            <SelectField source="paymentMethod" label={"Wpłata"} choices={[
                {id: 'cash', name: 'Zwykły przelew'},
                {id: 'przelewy24', name: 'Przelewy 24'},
            ]}/>
            <ReferenceField label="Kupon rabatowy" source="discountCoupon" reference="discount_coupons">
                <TextField source="code"/>
            </ReferenceField>
            <DateField source="maxActiveAt" label={"Pakiet można aktywować do"}/>
            <FunctionField source='status' label="Status"
                           render={record => paymentsStatus[record.status] || 'brak danych'}/>
            <ShowButton basePath='/purchases' label="Podgląd"/>
            <CheckboxField source={'status'} label={"Opłacono"}/>
        </Datagrid>
    </List>
}

export default OrderList;