import React from 'react'
import { ReferenceInput, SelectInput, useGetOne, AutocompleteInput } from "react-admin";

function DietTrainingInput(props) {
    const { data, loading, loaded, error, refetch } = useGetOne('products', props.record.product);

    if (loading) return <></>

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data?.isDietOffer && (
                <ReferenceInput {...props} reference={'diets'} source={'diet'} label={"Dieta"}
                                filterToQuery={(searchText) => {
                                    return {
                                        dietName: searchText.length > 4 ? searchText : 'fakeVal',
                                        pagination: false
                                    }
                                }}
                >
                    <AutocompleteInput label={"Dieta"} optionText="dietName" shouldRenderSuggestions={(value) => {
                        return value.length > 4
                    }}/>
                </ReferenceInput>
            )}
            {data?.isWorkoutOffer && (
                <ReferenceInput {...props} reference={'workouts'} source={'workout'} label={"Ćwiczenia"}
                                filterToQuery={(searchText) => {
                                    return {
                                        workoutName: searchText.length > 4 ? searchText : 'fakeVal',
                                        pagination: false,
                                    }
                                }}>
                    <AutocompleteInput label={"Ćwiczenia"} optionText="workoutName"
                                       shouldRenderSuggestions={(value) => {
                                           return value.length > 4
                                       }}/>
                </ReferenceInput>
            )}

        </div>
    );
};

export default DietTrainingInput
