import React, {cloneElement, useEffect, useState} from "react";
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Button,
    CreateContextProvider,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreateController,
    useDataProvider,
    useNotify
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";
import {useHistory} from "react-router";
import {CircularProgress} from "@material-ui/core";


const mapIdToName = (id) => {
    return fields.find(field => id.includes(field.target))?.name
}

const fields = [
    {
        name: 'exercisesGroupOne',
        target: 'workout_exercise_group_as'
    },
    {
        name: 'exercisesGroupTwo',
        target: 'workout_exercise_group_bs'
    },
    {
        name: 'exercisesGroupThree',
        target: 'workout_exercise_group_cs'
    },
    {
        name: 'exercisesGroupFour',
        target: 'workout_exercise_group_ds'
    },
]


const GoBackToolbar = (props) => {
    const history = useHistory();
    const {values} = useFormState();
    const dataProvider = useDataProvider()
    const notify = useNotify();
    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []

    const save = () => {

        delete values['orginId']
        delete values['@id']

        fields.forEach(field => {

            (values[field.name] || []).forEach((ex, index) => {

                tasks.push(dataProvider.create(field.target, {
                    data: {
                        workout: null,
                        exercise: ex,
                        workoutExerciseOrder: index + 1,
                    }
                }))

            })

        })

        const workouts = {}

        Promise.all(tasks).then(ex => {
            try {
                ex.forEach(i => {
                    if (workouts[mapIdToName(i.data.id)]) {
                        workouts[mapIdToName(i.data.id)].push(i.data.id)
                    } else {
                        workouts[mapIdToName(i.data.id)] = [i.data.id]
                    }
                })

                dataProvider.create('workouts', {
                    data: {
                        ...values,
                        ...workouts
                    }
                }).then((r) => {
                    onSuccess()
                }).catch((err) => {
                    notify('Wystąpił błąd', 'warning')
                })
            } catch (e) {
                console.error(e)
                notify('Wystąpił błąd', 'warning')
            }

        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};

const CustomCreate = props => {
    const [exercises, setExercises] = useState({
        exercisesGroupOne: [],
        exercisesGroupTwo: [],
        exercisesGroupThree: [],
        exercisesGroupFour: []
    })

    const dataProvider = useDataProvider();
    const controllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(async () => {

        const d = exercises
        const tasks = []

        for(let i = 0; i < fields.length; i++) {
            const field = fields[i]

            if (record && record[field.name]) {

                // const records = await


                tasks.push(dataProvider.getMany(field.target, {
                    ids:  record[field.name]
                }))

                //d[field.name] = records.data.sort((a,b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish)
            }
        }

        Promise.all(tasks).then(ex => {
            setExercises({
                exercisesGroupOne: ex[0]?.data.sort((a,b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupTwo: ex[1]?.data.sort((a,b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupThree: ex[2]?.data.sort((a,b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise),
                exercisesGroupFour: ex[3]?.data.sort((a,b) => a.workoutExerciseOrder - b.workoutExerciseOrder).map(a => a.exercise)
            })
        })


    }, [])


    if (!record) return <CircularProgress/>

    const newRecord = {
        ...controllerProps.record,
        ...exercises
        // ingredientsForDishes: ingredients?.sort((a,b) => a.ingredientDishOrder - b.ingredientDishOrder),
    }

    return <CreateContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </CreateContextProvider>
}


const TrainingCreate = (props) => {

    const dataProvider = useDataProvider();
    const transform = async data => {

        const tasks = []

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]

            if(data[field.name] && data[field.name].length > 0) {
                for (const ifd of data[field.name]) {

                    tasks.push(
                        dataProvider.create(field.target, {
                            data: {
                                workout: null,
                                exercise: ifd,
                                workoutExerciseOrder: data[field.name].indexOf(ifd)+1,
                            }
                        })
                    )
                }
            }

        }


        const exercises = await Promise.all(tasks)


        data = {
            ...data,
            exercisesGroupOne: [],
            exercisesGroupTwo: [],
            exercisesGroupThree: [],
            exercisesGroupFour: []
        }

        exercises.forEach(d => {
            data[mapIdToName(d.data.id)].push(d.data.id)
        })

        return data;
    };
    // const optionRender = record => `${record.name} - ${record.exerciseCode}`

    return (
        <CustomCreate title='Stwórz trening'  {...props}>
            <SimpleForm toolbar={<GoBackToolbar/>} redirect={"/workouts"} transform={transform}>
                <TextInput source='workoutName' label={'Nazwa treningu'}/>
                <TextInput source={"workoutCode"} label={"Kod treningu"}/>
                <ReferenceInput filterToQuery={() => ({pagination:false})} source="workoutTemplate" reference="workout_templates" label={"Szablon treningów"}>
                    <AutocompleteInput optionText="templateName" />
                </ReferenceInput>
                <TextInput source='workoutNote' label={'Notatka'}/>
                <RichTextInput source="introductionDescription" label="Strona wstępu"/>
                <RichTextInput source="descriptivePages" label="Strony opisowe"/>

                <span style={{fontWeight: "bold"}}>Trening A</span>
                <ReferenceArrayInput  filter={{ pagination: false }} source="exercisesGroupOne" reference="exercises" label="Trening A - ćwiczenia">
                    <AutocompleteArrayInput optionText={(item) => `${item?.name} - ${item?.exerciseCode}`} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}}  />
                </ReferenceArrayInput>
                <RichTextInput source={"exerciseGroupADescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening B</span>
                <ReferenceArrayInput filter={{ pagination: false }} source="exercisesGroupTwo" reference="exercises" label="Trening B - ćwiczenia">
                    <AutocompleteArrayInput optionText={(item) => `${item?.name} ${item?.exerciseCode}`} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}}  />
                </ReferenceArrayInput>
                <RichTextInput source={"exerciseGroupBDescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening C</span>
                <ReferenceArrayInput filter={{ pagination: false }} source="exercisesGroupThree" reference="exercises" label="Trening C - ćwiczenia">
                    <AutocompleteArrayInput optionText={(item) => `${item?.name} ${item?.exerciseCode}`} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}}  />
                </ReferenceArrayInput>
                <RichTextInput source={"exerciseGroupCDescription"} label={"Opis treningu"}/>

                <span style={{fontWeight: "bold"}}>Trening D</span>
                <ReferenceArrayInput filter={{ pagination: false }} source="exercisesGroupFour" reference="exercises" label="Trening D - ćwiczenia">
                    <AutocompleteArrayInput optionText={(item) => `${item?.name} ${item?.exerciseCode}`} classes={{"chipContainerFilled": "autocomplete-array-input-flex"}}  />
                </ReferenceArrayInput>
                <RichTextInput source={"exerciseGroupDDescription"} label={"Opis treningu"}/>

            </SimpleForm>
        </CustomCreate>
    )
}

export default TrainingCreate;