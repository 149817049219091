import * as React from 'react';
import { FC } from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useRecordContext } from 'ra-core';
import IconButton from "@material-ui/core/IconButton";
import RemoveCircle from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles(theme => (
    {
        list: {
            display: 'flex',
            listStyleType: 'none',
        },
        image: {
            margin: '0.5rem',
            maxHeight: '10rem',
        },
        removeButton: {},
        removeIcon: {
            color: theme.palette.error.main,
        },
    }),
    { name: 'RaImageField' }
);

const ImageField  = props => {
    const {
        className,
        classes: classesOverride,
        emptyText,
        source,
        src,
        title,
        ...rest
    } = props;
    const record = useRecordContext(props);
    const sourceValue = get(record, source);
    const classes = useStyles(props);
    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
                className={classnames(classes.list, className)}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title) || title;
                    const srcValue = get(file, src) || title;

                    return (
                        <li key={index}>
                            <img
                                alt={fileTitleValue}
                                title={fileTitleValue}
                                src={srcValue}
                                className={classes.image}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title) || title;

    return (
        <div style={{display: 'flex', alignItems: 'center'}} className={className}>
            <img
                title={titleValue}
                alt={titleValue}
                src={process.env.REACT_APP_IMAGE_URL + sourceValue}
                className={classes.image}
            />
            <IconButton
                className={classes.removeButton}
                onClick={props.onRemove}
            >
                <RemoveCircle className={classes.removeIcon} />
            </IconButton>
        </div>
    );
};

export default ImageField