import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import FuseScrollbars from '../../@fuse/core/FuseScrollbars'
import {DateField, FunctionField, ReferenceField, Show, SimpleShowLayout, useDataProvider} from 'react-admin'
import pl from "date-fns/locale/pl";
import IconButton from "@material-ui/core/IconButton";
import {Button, Checkbox, FormControlLabel, Icon, Paper, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {fade} from "@material-ui/core/styles/colorManipulator";
import io from 'socket.io-client';
import jwt_decode from "jwt-decode";
import generateAvatar from "./generateAvatar";
import Avatar from "@material-ui/core/Avatar";
import Notes from "./Notes";
import {NavLink} from "react-router-dom";
import {stringify} from 'query-string';
import {format} from "date-fns";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        zIndex: 10,
        background: `linear-gradient(to bottom, ${fade(theme.palette.background.paper, 0.8)} 0,${fade(
            theme.palette.background.paper,
            0.6
        )} 20%,${fade(theme.palette.background.paper, 0.8)})`
    },
    content: {
        display: 'flex',
        flex: '1 1 100%',
        minHeight: 0
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        position: 'relative',
        flex: '1 1 auto',
        height: 'auto',
        backgroundColor: theme.palette.background.default
    },
    contentCardWrapper: {
        position: 'relative',
        padding: 24,
        maxWidth: 1400,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        width: '100%',
        minWidth: '0',
        maxHeight: '100%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: 16
        },
        [theme.breakpoints.down('xs')]: {
            padding: 12
        }
    },
    contentCard: {
        display: 'flex',
        position: 'relative',
        flex: '1 1 100%',
        flexDirection: 'row',
        backgroundImage: 'url("/assets/images/patterns/rain-grey.png")',
        backgroundColor: theme.palette.background.paper,
        minHeight: 0,
        overflow: 'hidden'
    },
    messageRow: {
        '&.contact': {
            '& .bubble': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.getContrastText(theme.palette.background.paper),
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                '& .time': {
                    marginLeft: 12
                }
            },
            '&.first-of-group': {
                '& .bubble': {
                    borderTopLeftRadius: 20
                }
            },
            '&.last-of-group': {
                '& .bubble': {
                    borderBottomLeftRadius: 20
                }
            }
        },
        '&.me': {
            paddingLeft: 40,

            '& .avatar': {
                order: 2,
                margin: '0 0 0 16px'
            },
            '& .bubble': {
                marginLeft: 'auto',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                '& .time': {
                    justifyContent: 'flex-end',
                    right: 0,
                    marginRight: 12
                }
            },
            '&.first-of-group': {
                '& .bubble': {
                    borderTopRightRadius: 20
                }
            },

            '&.last-of-group': {
                '& .bubble': {
                    borderBottomRightRadius: 20
                }
            }
        },
        '&.contact + .me, &.me + .contact': {
            paddingTop: 20,
            marginTop: 20
        },
        '&.first-of-group': {
            '& .bubble': {
                borderTopLeftRadius: 20,
                paddingTop: 13
            }
        },
        '&.last-of-group': {
            '& .bubble': {
                borderBottomLeftRadius: 20,
                paddingBottom: 13,
                '& .time': {
                    display: 'flex'
                }
            }
        }
    }
}));

const ChatWrapper = (props) => {
    const classes = useStyles(props);

    return <div className={"flex flex-col lg:flex-row"}>
        <div className={clsx(classes.root) + " lg:w-1/2"} style={{resize: "horizontal", overflow: "auto"}}>
            <div className={clsx(classes.contentCardWrapper, 'container')} style={{height: '720px'}} >
                <div className={clsx(classes.contentCard, 'shadow')}>
                    <main className={clsx(classes.contentWrapper, 'z-10 w-full')} style={{width: "100%!important"}}>
                        <>
                            <div className={classes.content}>
                                <ChatWindow {...props} className="flex flex-1 z-10"/>
                            </div>
                        </>
                    </main>
                </div>
            </div>
        </div>
        <div className={clsx(classes.root) + " lg:w-1/2"}>
            <div className={clsx(classes.contentCardWrapper, 'container')} style={{height: '720px'}}>
                <div style={{background: 'none'}} className={clsx(classes.contentCard, 'shadow')}>
                    <main className={clsx(classes.contentWrapper, 'z-10')}>
                        <>
                            <div className={classes.content}>
                                <Notes  {...props} />
                            </div>
                        </>
                    </main>
                </div>
            </div>
        </div>

    </div>
}

const ChatWindow = (props) => {
    const classes = useStyles(props);
    const chatRef = useRef(null);
    const divRef = useRef(null);
    const [messageText, setMessageText] = useState('');
    const [messages, setMessages] = useState([]);
    const [conversationId, setConversationId] = useState(null)
    const [socket, setSocket] = useState(null);
    const inputRef = useRef(null);

    let decoded = jwt_decode(localStorage.getItem('token'));

    const id = decoded.userId

    useEffect(() => {

        const messageListener = (message) => {
            if (message.userId != id) {
                setMessages([...messages, {
                    content: message.message,
                    userDisplayName: message.userDisplayName,
                    createdAt: new Date(),
                    client: 'api/clients/' + message.userId,
                    admin: null
                }])
            }
        }

        if (socket) {
            socket.on('receivedMessage', messageListener)
        }

        return () => {
            if (socket) {
                socket.off('receivedMessage', messageListener)
            }
        }
    }, [socket, messages])

    useEffect(() => {

        if (conversationId !== null) {
            const newSocket = io(process.env.REACT_APP_IMAGE_URL, {
                path: '/ws_chat',
                query: {
                    authToken: localStorage.getItem('token'),
                    conversationId: conversationId
                }
            });

            setSocket(newSocket);
            return () => {
                newSocket.close();
            }
        }
    }, [localStorage.getItem('token'), conversationId]);

    useEffect(() => {
        if (props.record.originId) {
            setConversationId(props.record.originId);
            fetch(process.env.REACT_APP_IMAGE_URL + `api/conversations/${props.record.originId}/messages?pagination=false`, {
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => res.json()).then(data => {
                setMessages(data.reverse())
            })
        }

    }, [props.record.originId])

    useEffect(() => {
        if (chatRef.current && divRef.current) {
            const resizeObserver = new ResizeObserver(scrollToBottom)
            resizeObserver.observe(divRef.current)

            return () => {
                if (divRef.current) {
                    resizeObserver.unobserve(divRef.current)
                }
            }
            // setTimeout(() => scrollToBottom(), 100);
        }
    }, [messages.length]);


    function scrollToBottom(e) {
        if (e && e[0] && chatRef.current && divRef.current) {
            chatRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }

    function shouldShowContactAvatar(item, i) {
        return item.userDisplayName && item.client
    }

    function isFirstMessageOfGroup(item, client, i) {
        return i === 0 || (messages[i - 1] && messages[i - 1][client ? 'client' : 'admin'] !== (client ? item.client : item.admin));
    }

    function isLastMessageOfGroup(item, client, i) {
        return i === messages.length - 1 || (messages[i + 1] && messages[i + 1][client ? 'client' : 'admin'] !== (client ? item.client : item.admin));
    }

    function onInputChange(ev) {
        setMessageText(ev.target.value);
    }

    function sendMessage(cId, text, type = 'txt') {
        socket.emit('message', JSON.stringify({type: type, 'message': text}))
        setMessages([...messages, {
            content: JSON.stringify({type: type, 'message': text}),
            createdAt: new Date(),
            admin: 'api/users/',
            client: null
        }])
    }

    function onMessageSubmit(ev) {
        ev.preventDefault();
        if (messageText === '') {
            return;
        }


        setTimeout(() => {
            sendMessage(conversationId, messageText)
        }, 400)

        setMessageText('')

        // dispatch(
        //     sendMessage({
        //         messageText,
        //         chatId: chat.id,
        //         contactId: selectedContactId
        //     })
        // ).then(() => {
        //     setMessageText('');
        // });
    }

    const clickInput = () => {
        inputRef.current.click();

    }

    const onFileChange = (e) => {
        if (e.target.files && e?.target?.files[0]) {
            const file = e?.target?.files[0];
            const formData = new FormData();
            formData.append("file", e?.target?.files[0]);
            fetch(process.env.REACT_APP_API_URL + `/api/attachments`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
                method: "POST",
                body: formData
            }).then(response => {
                return response.json()
            }).then(json => {
                sendMessage(conversationId, json.contentUrl, 'img')
            })
        }
    }

    return <>

        <FuseScrollbars ref={chatRef} className="flex flex-1 flex-col overflow-y-auto">
            {messages.length > 0 ? (
                <div ref={divRef} key={'l' + messages.length}
                     className="flex flex-col pt-16 px-16 ltr:pl-56 rtl:pr-56 pb-40">
                    {messages.map((item, i) => {

                        return (
                            <div
                                key={i}
                                className={clsx(
                                    classes.messageRow,
                                    'flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative px-16 pb-4',
                                    {me: !!item.admin},
                                    {contact: !!item.client},
                                    {'first-of-group': isFirstMessageOfGroup(item, !!item.client, i)},
                                    {'last-of-group': isLastMessageOfGroup(item, !!item.client, i)},
                                    i + 1 === messages.length && 'pb-96'
                                )}
                            >
                                {shouldShowContactAvatar(item, i) && (
                                    <Avatar
                                        style={{
                                            top: '42px',
                                            left: '4px'
                                        }}
                                        className="avatar absolute ltr:left-0 rtl:right-0 m-0 -mx-32"
                                        src={generateAvatar(item.userDisplayName, 32)}
                                    />
                                )}
                                <div
                                    className="bubble flex relative items-center justify-center p-12 max-w-full shadow">
                                    {JSON.parse(item.content)?.type == 'img' &&
                                    <img src={process.env.REACT_APP_API_URL + JSON.parse(item.content).message}
                                         alt={JSON.parse(item.content).id}/>}

                                    {(JSON.parse(item.content)?.type == 'txt' || JSON.parse(item.content)?.type == undefined) &&
                                    <div
                                        className="leading-tight whitespace-pre-wrap">{JSON.parse(item.content).message}</div>}
                                    {/*<Typography*/}
                                    {/*    className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"*/}
                                    {/*    color="textSecondary"*/}
                                    {/*    style={{top: '48px'}}*/}
                                    {/*>*/}

                                    {/*</Typography>*/}
                                </div>
                                <p style={!item.client ? {
                                    textAlign: "right",
                                    width: "100%"
                                } : null}>{format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm')}</p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 items-center justify-center">
                        <Icon className="text-128" color="disabled">
                            chat
                        </Icon>
                    </div>
                    <Typography className="px-16 pb-24 text-center" color="textSecondary">
                        Rozpocznij konwersacje wpisując wiadomość poniżej.
                    </Typography>
                </div>
            )}
        </FuseScrollbars>
        <form onSubmit={onMessageSubmit} className="flex absolute bottom-0 right-0 left-0 py-16 px-8">
            {/*<Button variant="contained" className="self-center" type="submit" style={{*/}
            {/*    marginRight: '15px',*/}
            {/*    padding: '10px 30px',*/}
            {/*    borderRadius: "20px",*/}
            {/*    background: theme.colors.orange*/}
            {/*}}>*/}
            {/*    Ogólny*/}
            {/*</Button>*/}
            <Paper className="flex flex-grow items-center relative rounded-24 shadow">
                <TextField
                    autoFocus={false}
                    multiline
                    id="message-input"
                    className="flex-1"
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            root: 'flex flex-grow mx-16 ltr:mr-48 rtl:ml-48 my-8',
                            input: ''
                        },
                        placeholder: 'Wpisz wiadomość'
                    }}
                    InputLabelProps={{
                        shrink: false,
                        className: classes.bootstrapFormLabel
                    }}
                    onChange={onInputChange}
                    value={messageText}
                    style={{maxHeight: "500px", overflow: "auto"}}
                />
                <input onChange={onFileChange} ref={inputRef} style={{visibility: 'hidden', width: 0}} type="file"
                       accept=".png, .jpg, .jpeg"/>
                <IconButton onClick={clickInput} className="absolute ltr:right-32 rtl:left-0 top-0" type="button">
                    <Icon className="text-24" color="action">
                        image
                    </Icon>
                </IconButton>
                <IconButton className="absolute ltr:right-0 rtl:left-0 top-0" type="submit">
                    <Icon className="text-24" color="action">
                        send
                    </Icon>
                </IconButton>
            </Paper>
        </form>

    </>
}

function Chat({...props}) {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceField label="Imie i nazwisko" reference="clients" source="client">
                    <FunctionField render={record => `${record.name} ${record.surname}`}/>
                </ReferenceField>
                <DateField source='updatedAt' label="Data ostaniej wiadomości"/>
                <Buttons/>
                <ChatWrapper/>
            </SimpleShowLayout>
        </Show>
    );
};

const Buttons = (props) => {
    const dataProvider = useDataProvider()
    const [client, setCilent] = useState(null)
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)

    const handleChange = (event) => {
        dataProvider.update(props.resource, {
            id: props.record.id, data: {
                isAnsweredByAdmin: event.target.checked
            }
        })
    };

    useEffect(() => {
        if (props?.record?.client) {
            dataProvider.getOne('clients', {id: props?.record?.client}).then(response => {
                setCilent(response.data)
            }).then(() => setLoaded(true))
        }
    }, [props?.record?.client])

    const createConversation = () => {
        dataProvider.create('conversations', {
            data: {
                client: client?.slaveAccounts[0]
            }
        }).then(response => {
            history.push('/conversations/' + encodeURIComponent(response?.data?.id) + '/show',)
        })
    }

    return <div style={{display: 'flex'}}>
        {loaded && <><Button component={NavLink}
                             to={{
                                 pathname: '/silhouettes_photos',
                                 search: stringify({
                                     page: 1,
                                     perPage: 25,
                                     filter: JSON.stringify({"client.helperId": client?.helperId}),
                                 }),
                             }}
        >Zdjęcia sylwetki</Button>
            <Button component={NavLink}
                    to={{
                        pathname: '/measurement_forms',
                        search: stringify({
                            page: 1,
                            perPage: 25,
                            filter: JSON.stringify({"client.helperId": client?.helperId}),
                        }),
                    }}>Pomiary</Button>
            <Button component={NavLink}
                    to={{
                        pathname: '/questionnaires',
                        search: stringify({
                            page: 1,
                            perPage: 25,
                            filter: JSON.stringify({"owner.helperId": client?.helperId}),
                        }),
                    }}
            >Ankiety</Button></>}
        {(props?.record?.relatedConversations && props?.record?.relatedConversations?.length > 0) &&
        <Button component={NavLink}
                to={{
                    pathname: '/conversations/' + encodeURIComponent(props?.record?.relatedConversations[0]) + '/show',
                }}
        >Przełącz profil</Button>
        }
        {((client && client?.slaveAccounts && (client?.slaveAccounts?.length > 0)) && !(props?.record?.relatedConversations && props?.record?.relatedConversations?.length > 0)) &&
        <Button onClick={createConversation}
        >Przełącz profil</Button>
        }

        <FormControlLabel
            style={{marginLeft: 'auto'}}
            control={<Checkbox checked={props?.record?.isAnsweredByAdmin} onChange={handleChange}
                               name="isAnsweredByAdmin"/>}
            label="Odpisano"
        />
    </div>
}


export default Chat