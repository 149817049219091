import React, {cloneElement, useEffect, useState} from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    TextField,
    AutocompleteInput,
    DateField,
    ReferenceArrayInput,
    SelectArrayInput,
    useGetList,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    Button,
    useDataProvider,
    useEditController,
    EditContextProvider
} from 'react-admin';
import {useField} from "react-final-form";
import {pick} from "lodash/object";
import {groupBy} from 'lodash'
import {CircularProgress} from "@material-ui/core";


const CustomEdit = props => {
    const [questions, setQuestions] = useState(null)

    const dataProvider = useDataProvider();
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(async () => {
        if (record?.questions) {
            let arr = []
            for (const question of record.questions) {
                const record = await dataProvider.getOne('questions', {
                    id: question
                })
                if (record?.data) {
                    arr.push(record?.data);
                }
            }
            setQuestions(arr)
        }
    }, [record?.questions])



    if(!record) return <CircularProgress />





    const newRecord = {
        ...controllerProps.record,
        questions: questions?.map(q => ({question: q.id})),
    }


    return <EditContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </EditContextProvider>
}

const QuestionnaireListEdit = (props) => {

    const transform = async data => {
        if(data.questions && data.questions.length > 0){
            const mapped = data.questions.map(q => q.question)

            data.questions = mapped
        }

        return data;
    };

    return (
        <CustomEdit title='Edytuj...'  {...props} transform={transform}>
            <SimpleForm>
                <TextInput source='title' label="Nazwa" />
                <DateInput source='createdAt' disabled={true} label="Data dodania" />
                <DateInput source='updatedAt' disabled={true} label="Data edycji" defaultValue={new Date()} />
                <ReferenceArrayInput reference={'products'} source={'products'} label={"Przypisane pakiety"} perPage={30}>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                <CategoryQuestion label={'Pyatnia'} />
            </SimpleForm>
        </CustomEdit>
    )
}

const CategoryQuestion = (props) => {
    const field = useField("questions")
    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'questions',
        {page:1, perPage:1000},
        undefined,
         {isClientQuestion: false}
    );


    const choices = (el) => {
        return Object.values(el).map( (e) => ({
            id: e.id,
            name: e.question
        }))
    }


    return <ArrayInput {...props} source={"questions"} label={''}>
        <SimpleFormIterator addButton={<CustomAddButton/>}>
            <AutocompleteInput label={'Pytanie'} source={'question'} choices={choices(data)}/>
        </SimpleFormIterator>
    </ArrayInput>
}

const CustomAddButton = (props) => {
    return <Button {...props} label={'Dodaj pytanie'}/>
}



export default QuestionnaireListEdit;