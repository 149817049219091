import { dataProviderHydra } from "../App";
import jwt_decode from "jwt-decode";

const redirectTable = {
    "isUsersAccess": '/users',
    "isGroupsAccess": '/groups',
    "isClientsAccess": '/clients',
    "isDishesAccess": '/diets',
    "isQuestionnaireAccess": '/questionnaire_templates',
    "isClientsQuestionnaireAccess": '/questionnaires',
    "isPurchasesAccess": '/purchases',
    "isProductsAccess": '/purchases',
    "isCouponsAccess": '/discount_coupons',
    "isExercisesAccess": '/workouts',
    "isFAQAccess": '/faqs',
    "isMeasurementFormsAccess": '/measurement_forms',
    "isSilhouettePhotosAccess": '/silhouettes_photos',
    "isChatAccess": '/conversations',
    "isFlashMessageAccess": '/news'
}

const authProvider = {
    // authentication
    login: async ({ username, password }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                email: username,
                password: password,
            })
        })

        if (!response.ok) {
            return Promise.reject('Nieprawidłowe dane logowania')
        }

        const data = await response.json();

        try {
            const decoded = jwt_decode(data.token)
            if (!decoded.isActive) {
                return Promise.reject('To konto zostało zablokowane!')
            }
            localStorage.setItem('token', data.token)
            localStorage.setItem('refreshToken', data.refresh_token)
            await dataProviderHydra.introspect()
        } catch (e) {
            console.error(e)
        }
    },
    checkError: (error) => new Promise((resolve, reject) => {
        const status = error.status || error.response.status;

        if (status === 422) {
            return resolve()
        }

        if (status === 404) {
            return resolve()
        }

        if (status === 403) {
            try {
                const { userGroupAccess } = jwt_decode(localStorage.getItem('token'))

                const k = Object.entries(userGroupAccess)?.find(([key, value]) => value)
                if (k) return reject({ redirectTo: redirectTable[k[0]], logoutUser: false, message: false })
                return reject({ redirectTo: '/permissions', logoutUser: false, message: false });
            } catch (e) {
                console.error(e)
            }

        }

        if (status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');

            return reject({ redirectTo: '/login', message: false });
        }
        return resolve();
    }),
    checkAuth: async () => {
        const refreshToken = localStorage.getItem('refreshToken')
        if (!refreshToken) return Promise.reject({ redirectTo: '/login', message: false })

        const response = await fetch(process.env.REACT_APP_API_URL + '/api/auth/refresh_token', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                refresh_token: refreshToken,
            })
        })

        if (!response.ok || response.status === 401) {
            return Promise.reject()
        } else {
            const data = await response.json()
            localStorage.setItem('token', data.token)
            localStorage.setItem('refreshToken', data.refresh_token)
        }

        return true;
    },
    logout: async (data) => {
        if (data === null) {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
        }
    },

    getIdentity: () => {
        return localStorage.getItem('token')
    },
    // authorization
    getPermissions: (params) => new Promise((resolve, reject) => {
        resolve()
    }),
}

export default authProvider
