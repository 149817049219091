import React, {useEffect, useState} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    TextField,
    DateField,
    FileInput,
    ImageField,
    ImageInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    ReferenceArrayField,
    BooleanInput,
    useDataProvider, useNotify, useGetList, Toolbar, Button
} from 'react-admin';
import CustomArrayImageField from "../Fields/CustomArrayImageField";
import {useHistory} from "react-router";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";


const GoBackToolbar = (props) => {
    const history = useHistory();
    const {values, ...rest} = useFormState();
    const dataProvider = useDataProvider();
    const notify = useNotify()

    const [oldImg, setOldImg] =useState(values.photos[0])
    const [newImg, setNewImg] =useState('')

    const onSuccess = () => {
        history.goBack();
    };

    useEffect(()=> {
        fetch(process.env.REACT_APP_IMAGE_URL+oldImg.slice(1), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Access-Control-Allow-Origin': window.location.hostname
            },
        }).then(res => res.json()).then(data => {
            delete data['id']
            setNewImg(data)
        });
    },[])

    const tasks = []
    const save = () => {

        delete values['orginId']
        delete values['@id']

        Promise.all(tasks).then(() => {
            dataProvider.create('exercises', {data: {...values}}).then(() => onSuccess())
        })
    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};

const ExerciseCreate = (props) => {
    return (
        <Create undoable={false} title='Stwórz ćwiczenie'  {...props}>
            <SimpleForm undoable={false} redirect={'/exercises'} toolbar={<GoBackToolbar/>}>
                <TextInput source='name' label="Nazwa ćwiczenia"/>
                <TextInput source={"exerciseCode"} label={"Kod ćwiczenia"}/>
                <TextInput multiline source='description' label="Opis ćwiczenia"/>
                <ReferenceArrayField label={"Zdjęcia"} source="photos" reference="attachments">
                    <CustomArrayImageField name={'photos'}/>
                </ReferenceArrayField>
                <TextInput source='videoUrl' label="Link do filmu"/>
                <ReferenceArrayInput source="muscles" reference="muscles" label="Trenowana partia mięśni">
                    <SelectArrayInput optionText="muscleName"/>
                </ReferenceArrayInput>
                <TextInput source='englishName' label="Nazwa ćw. w języku angielskim"/>
                <TextInput source='quantityOfIterations' label="Ilość powtórzeń"/>
                <NumberInput source='quantityOfSeries' label="Ilość serii"/>
                <TextInput source='breakBetweenSeries' label="Przerwa między seriami"/>
                <TextInput source='mainMusclesDescription' label="Główne mięśnie"/>
                <TextInput source='helpfulMusclesDescription' label="Mięśnie pomocnicze"/>
                <BooleanInput label="Nie publikuj w Atlasie ćwiczeń" source="isVisiblePublicly"/>
            </SimpleForm>

        </Create>
    )
}

export default ExerciseCreate;