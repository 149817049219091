import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    DateField,
    ImageInput,
    ImageField,
    FileInput,
    SelectInput,
    SelectArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    ReferenceArrayField,
    BooleanInput
} from 'react-admin';
import CustomArrayImageField from "../Fields/CustomArrayImageField";
import {GoBackToolbar} from "../IngredientDish/IngredientDishEdit";

const ExerciseEdit = (props) => {

    const transform = async (data) => {
        return {
            ...data,
            videoUrl: data.videoUrl ?? "",
        }
    }
    return (
        <Edit transform={transform} undoable={false} title='Edytuj ćwiczenie'  {...props}>
            <SimpleForm undoable={false} toolbar={<GoBackToolbar/>}>
                <TextInput source='name' label="Nazwa ćwiczenia" />
                <TextInput source={"exerciseCode"} label={"Kod ćwiczenia"}/>
                <TextInput multiline source='description' label="Opis ćwiczenia"/>
                <ReferenceArrayField label={"Zdjęcia"} source="photos" reference="attachments">
                    <CustomArrayImageField name={'photos'} />
                </ReferenceArrayField>

                <TextInput source='videoUrl' label="Link do filmu"/>
                <ReferenceArrayInput source="muscles" reference="muscles" label="Trenowana partia mięśni" >
                    <SelectArrayInput optionText="muscleName" />
                </ReferenceArrayInput>
                <TextInput source='englishName' label="Nazwa ćw. w języku angielskim"/>
                <TextInput source='quantityOfIterations' label="Ilość powtórzeń"/>
                <NumberInput source='quantityOfSeries' label="Ilość serii"/>
                <TextInput source='breakBetweenSeries' label="Przerwa między seriami"/>
                <TextInput source='mainMusclesDescription' label="Główne mięśnie"/>
                <TextInput source='helpfulMusclesDescription' label="Mięśnie pomocnicze"/>
                <BooleanInput label="Nie publikuj w Atlasie ćwiczeń" source="isVisiblePublicly" />
            </SimpleForm>

        </Edit>
    )
}


export default ExerciseEdit;