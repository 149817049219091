import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    EmailField,
    TopToolbar,
    NumberField,
    SelectField,
    ReferenceField,
    FunctionField,
    SingleFieldList,
    ChipField,
    ShowButton,
    DeleteButton,
    EditButton,
    useDataProvider
} from 'react-admin';
import DietTrainingField from "../Fields/DietTrainingField";
import {paymentsStatus} from "./OrderList";
import {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {NavLink} from "react-router-dom";

import {stringify} from 'query-string';
import {useHistory} from "react-router";

const PostShowActions = ({basePath, data, resource}) => {

    return (
        <TopToolbar>
            {/*{data?.status >= 3  && <EditButton label={'Przypisz dietę i trening'} basePath={basePath} record={data} />}*/}
            {/* Add your custom actions */}
        </TopToolbar>
    );
}

const PostTitle = ({record}) => {
    return <span>Zamówienie {record ? `${record.helperId}` : ''}</span>;
};

const OrderShow = (props) => {
    return (
        <Show title={<PostTitle/>} actions={<PostShowActions/>} {...props}>
            <SimpleShowLayout>
                <TextField label={'Id'} source='helperId'/>
                <ReferenceField label="Imię i nazwisko" source="client" reference="clients">
                    <FunctionField render={record => `${record.name} ${record.surname}`}/>
                </ReferenceField>
                <ChatButton source={'client'}/>
                <ReferenceField label="Adres email" source="client" reference="clients">
                    <TextField source={"email"}/>
                </ReferenceField>
                <ReferenceField label="Wykupiony pakiet" source="product" reference="products">
                    <TextField source="name"/>
                </ReferenceField>
                <FunctionField source='finalPrice' label="Cena" render={record => `${record.finalPrice / 100} zł`}/>
                <FunctionField source='status' label="Status"
                               render={record => paymentsStatus[record.status] || 'brak danych'}/>
                <DietTrainingField/>
            </SimpleShowLayout>
        </Show>
    );
}

export const ChatButton = (props) => {
    const dataProvider = useDataProvider()
    const [client, setClient] = useState(null)
    const [relatedClient, setRelatedClient] = useState(null)
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        dataProvider.getOne('clients', {id: props?.record[props.source]}).then(response => {
            setClient(response.data)
        }).then(()=> setLoaded(true))
    }, [props?.record?.client,props?.record?.owner])


    const createConversation = () => {

        dataProvider.create('conversations', {
            data: {
                client: client?.id
            }
        }).then(response => {
            history.push('/conversations/' + encodeURIComponent(response?.data?.id) + '/show',)
        })
    }

    useEffect(() => {
        if (client?.slaveAccounts && client?.slaveAccounts.length > 0  ) {
            dataProvider.getOne('clients', {id: client?.slaveAccounts[0]}).then(response => {
                setRelatedClient(response.data)
            })
        }
        if (client?.masterAccount && client?.masterAccount.length > 0  ) {
            dataProvider.getOne('clients', {id: client?.masterAccount}).then(response => {
                setRelatedClient(response.data)
            })
        }
    }, [client])


    return <>{client?.conversation ? <Button component={NavLink}
                                             to={{
                                                 pathname: '/conversations/' + encodeURIComponent(client?.conversation) + '/show',
                                             }}
    >Otwórz chat</Button> : <Button onClick={createConversation}>Otwórz chat</Button>}
        {(props.buttons && loaded) && <>
            <Button component={NavLink}
                    to={{
                        pathname: '/silhouettes_photos',
                        search: stringify({
                            page: 1,
                            perPage: 25,
                            filter: JSON.stringify({"client.helperId": client?.helperId}),
                        }),
                    }}
            >Zdjęcia sylwetki</Button>
            <Button component={NavLink}
                    to={{
                        pathname: '/measurement_forms',
                        search: stringify({
                            page: 1,
                            perPage: 25,
                            filter: JSON.stringify({"client.helperId": client?.helperId}),
                        }),
                    }}>Pomiary</Button>


            {(props?.record?.parentForRelatedQuestionnaire || props?.record?.childOfRelatedQuestionnaire) &&
            <Button component={NavLink}
                    to={{
                        pathname: '/questionnaires/' + encodeURIComponent(props?.record?.childOfRelatedQuestionnaire || props?.record?.parentForRelatedQuestionnaire) + '/show',
                    }}
            >Przełącz profil</Button>}

            {relatedClient && relatedClient.questionnaires.length > 0 &&
            <Button component={NavLink}
                    to={{
                        pathname: '/questionnaires/' + encodeURIComponent(relatedClient?.questionnaires[0])  + '/show',
                    }}
            >Przełącz profil</Button>}

        </>}</>
}

export default OrderShow;