import React from "react";
import {
    NumberInput,
    Edit,
    SimpleForm,
    TextInput,
    Create,
} from "react-admin";

// import { GoBackToolbar } from "../Programs/ProgramEdit";

function AnswerCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm redirect={'/questions'}>
                <TextInput source={"answer"} label={"Odpowiedź"}/>
                <NumberInput label={"Kolejność"} source={"order"}/>
            </SimpleForm>
        </Create>
    );
}

export default AnswerCreate;
