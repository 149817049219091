import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    RichTextField,
    ReferenceField,
    FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const couponStatus  = ['Aktywny', 'Niekatywny']

 const FlashList = (props) => {

    return <List {...props} exporter={false}>
        <Datagrid>
            <TextField source='newsContent' label="Treść wiadomości"/>
            <NumberField source='newsOrder' label="Kolejność" />
            <DateField source={"createdAt"} label={"Utworzono"} />

            <EditButton basePath='/news' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/news' label="Usuń" />
        </Datagrid>
    </List>
}

export default FlashList;