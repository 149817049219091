import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, DateField, EditButton, DeleteButton, ReferenceInput, useList, useGetList,
} from 'react-admin';

const FullNameField = ({ record }) => <span>{record.name} {record.surname}</span>;

const ClientMsgCreate = (props) => {
    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'conversations',
        { page: 1, perPage: 1000 }
    );

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const userValidation = (value, allValues) => {
        const error = []
        Object.values(data).map(e => {
            if (e.client === value) {
                error.push('Konwersacja już istnieje');
            }
        })

        return error[0];
    };

    const validate = [required(), userValidation]

    return (
        <Create title='Wiadomość do klienta...'  {...props}>
            <SimpleForm redirect={'/conversations'}>
                <ReferenceInput reference={"clients"} source={"client"} label={"Klient"}>
                    <SelectInput optionText={<FullNameField/>} validate={validate}></SelectInput>
                </ReferenceInput>

                {/*<EditButton basePath='/clientMsg' label="Edytuj"/>*/}
                {/*<DeleteButton basePath='/clientMsg' label="Usuń" />*/}
            </SimpleForm>

        </Create>
    )
}

export default ClientMsgCreate;