import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, RichTextField, EditButton, DeleteButton, ReferenceInput, ReferenceArrayField, Toolbar, SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import CustomArrayImageField from "../Fields/CustomArrayImageField";
import {useHistory} from "react-router";
import {Button} from "@material-ui/core";

const GoBackToolbar = (props) => {
    const history = useHistory();

    const onSuccess = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onClick={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
}

const ClientImagesCreate = (props) => {
    return (
        <Create title='Dodaj zdjęcia'  {...props}>
            <SimpleForm toolbar={<GoBackToolbar/>}>
                <ReferenceInput source="client" reference="clients" label="Wybór użytkownika">
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source={'title'} label={"Nazwa zdjęć"}/>

                <ReferenceArrayField label={"Zdjęcia"} source="photos" reference="attachments">
                    <CustomArrayImageField max={3} name={'photos'} />
                </ReferenceArrayField>

                <DateInput source="photosDate" defaultValue={new Date} />
                <DateInput disabled={true} source="createdAt" defaultValue={new Date()} />
                {/*<NumberInput source='kcal' label="Ilośc kalorii"/>*/}
                {/*<TextInput source="ingridients" label="Składniki" />*/}

                {/*<NumberInput source='usage' label="Ilość użycia w dietach"/>*/}
                {/*<DateInput source='publishedAt' label="Data dodania"/>*/}
            </SimpleForm>

        </Create>
    )
}

export default ClientImagesCreate;