import React from 'react'
import {PasswordInput} from "react-admin";
import {Button} from "@material-ui/core";
import {useField} from "react-final-form";

function PasswordCustomInput(props) {
    const {input} = useField(props.source)

    const newPassword = () => {
        input.onChange('A' + Math.random().toString(36).substr(2, 8) + '!' + Math.round(Math.random()))
    }

 return (
  <div style={{display:'flex', alignItems: 'center'}}>
   <PasswordInput {...props}/>
      <Button onClick={newPassword} style={{marginTop: '-18px', marginLeft:'10px'}} color={'primary'}>Generuj hasło</Button>
  </div>
 );
};

export default PasswordCustomInput