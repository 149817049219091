import React from 'react'
import FuseAnimate from "../@fuse/core/FuseAnimate";
import {Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";

function Permissions({}) {
 return (
     <div className="flex flex-col flex-1 items-center justify-center p-16">
         <div className="max-w-512 text-center">


             <FuseAnimate delay={500}>
                 <Typography variant="h5" color="textSecondary" className="mb-16">
                     Brak dostępu
                 </Typography>
             </FuseAnimate>

             <FuseAnimate delay={600}>
                 <Typography variant="subtitle1" color="textSecondary" className="mb-48">
                     To konto nie ma uprawnień do przeglądania tego modułu
                 </Typography>
             </FuseAnimate>

             <NavLink className="font-medium" to="/users">
                 Wróć do panelu
             </NavLink>
         </div>
     </div>
 );
};


export default Permissions