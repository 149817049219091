import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput, SelectField
} from 'react-admin';


const IngredientCategoryCreate = (props) => {
    return (
        <Create title='Stwórz kategorię potrawy...'  {...props}>
            <SimpleForm redirect={'/dish_categories'}>
                <TextInput source="title" label="Nazwa kategorii" />
                <SelectInput source="color" label="Kolor kategorii" choices={[
                    { id: '#ffe49a', name: 'Żółty' },
                    { id: '#f2b087', name: 'Pomarańczowy' },
                    { id: '#a7d08c', name: 'Zielony' },
                    { id: '#acaba9', name: 'Szary' },
                    { id: '#a7d08d', name: 'Zielony - jasny' },
                    { id: '#bdd6ee', name: 'Niebieski - jasny' },
                ]} />

                <SelectInput source="dishType" label="Kategoria" choices={[
                    { id: 1, name: 'Śniadanie' },
                    { id: 2, name: 'II śniadanie' },
                    { id: 3, name: 'Obiad' },
                    { id: 4, name: 'II obiad' },
                    { id: 5, name: 'Kolacja' },
                    { id: 6, name: 'Deser' },
                    { id: 7, name: 'Przekąska' },
                ]} />
            </SimpleForm>

        </Create>
    )
}

export default IngredientCategoryCreate;