import React from 'react'
import {ArrayInput, BooleanInput, NumberInput, SimpleFormIterator, TextInput} from "react-admin";
import {useField} from "react-final-form";

function QuestionInput(props) {
    const {input} = useField('isOpenQuestion')



 return (
  <>
      <BooleanInput {...props} label={"Odpowiedź otwarta"} source={'isOpenQuestion'} looseValue={true}/>
      {!input.value && <div><div>Odpowiedzi</div>
      <ArrayInput {...props} label=""
                  reference="answers"
                  source="answers">
          <SimpleFormIterator>
              <TextInput label="Odpowiedź" source={"answer"} />
              <NumberInput label="Kolejność" source={"answerOrder"} />
          </SimpleFormIterator>
      </ArrayInput></div>}
  </>
 );
};


export default QuestionInput