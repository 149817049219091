import React, {cloneElement, useEffect, useState} from "react";
import {
    Edit,
    NumberInput,
    SaveButton,
    Toolbar,
    SelectInput,
    DeleteButton,
    ReferenceArrayInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceArrayField,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    EditButton, SimpleFormIterator, ArrayInput, useDataProvider, useEditController, EditContextProvider,
} from "react-admin";
import CountField from "../../components/Fields/CountField";
import {omit, pick} from "lodash/object";
import {CircularProgress} from "@material-ui/core";
import QuestionInput from "../Fields/QuestionInput";
// import { GoBackToolbar } from "../Programs/ProgramEdit";


const CustomEdit = props => {
    const [answers, setAnswers] = useState(null)
    const dataProvider = useDataProvider();
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;

    useEffect(async () => {
        if (record?.answers) {
            let arr = []
            for (const answer of record.answers) {
                const record = await dataProvider.getOne('answers', {
                    id: answer
                })
                if (record?.data) {
                    arr.push(pick(record.data, ['answer', 'answerOrder']));
                }
            }
            setAnswers(arr)
        }
    }, [record?.answers])

    if(!record) return <CircularProgress />
    const newRecord = {
        ...controllerProps.record,
        answers: answers?.sort((a, b) => a.answerOrder - b.answerOrder),
    }
    return <EditContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </EditContextProvider>
return null
}

function QuestionAddEdit({ onCancel, ...props }) {
    const dataProvider = useDataProvider()
    const transform = async data => {
        if(data.answers && data.answers.length > 0) {
            const mapped = [];
            for (const ifd of data.answers) {
                    const record = await dataProvider.create('answers', {
                        data: {...ifd, answerOrder: data.answers.indexOf(ifd), question: null}
                    })

                    mapped.push(record?.data?.id);

            }

            data.answers = mapped;
        }

        return data;
    };

    return (
        <CustomEdit {...props} transform={transform}>
            <SimpleForm undoable={false}>
                <TextInput source="question" label={"Pytanie"} />
                <NumberInput label={"Kolejność"} source={"questionOrder"} />
                <ReferenceInput label="Kategoria pytań" source="questionCategory" reference="question_categories">
                    <SelectInput required={true} optionText="title" />
                </ReferenceInput>

                <QuestionInput/>

            </SimpleForm>
        </CustomEdit>
    );
}

export default QuestionAddEdit;
