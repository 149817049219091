import {
    BooleanField,
    Create,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    Edit,
    TextInput, BooleanInput
} from "react-admin";
import React from "react";
import RichTextInput from "ra-input-rich-text";
import {useField} from "react-final-form";


const TrainingSchemaCreate = (props) => {
    return <Edit title='Edytuj szablon treningu' {...props}>
        <SimpleForm undoable={false}>
            <TextInput source='templateName' label="Nazwa szablonu"/>
            <TextInput source='workoutName' label="Nazwa treningu" />
            <RichTextInput source='shortWorkoutDescription' label="Rodzaj i krótki opis treningu"/>
            <RichTextInput source='trainingDays' label={'Ilość dni treningowych'}/>
            <RichTextInput source='trainingStaff' label="Sprzęt do ćwiczeń"/>
            <RichTextInput source='trainingParts' label="Podział treningu"/>
            <CardioInput/>
        </SimpleForm>
    </Edit>
}

export const CardioInput = (props) => {
    const {
        input,
        meta
    } = useField('isCardio');

    return <>
        <BooleanInput label="Kardio" source="isCardio" {...props} />
        {input.value && <NumberInput source={'cardioTime'} label={'Czas trawnia kardio'}/>}
    </>
}

export  default TrainingSchemaCreate;