export default function(name, size=20){
  var initials = name?.split(' ').map(function(str) { return str ? str[0].toUpperCase() : "";}).join('');
  var canvas = document.createElement('canvas');
  var radius = size;
  var margin = 4;
  canvas.width = radius*2+margin*2;
  canvas.height = radius*2+margin*2;

  // Get the drawing context
  var ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.arc(radius+margin,radius+margin,radius, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fillStyle = 'grey';
  ctx.fill();
  ctx.fillStyle = "white";
  ctx.font = `bold ${size}px Arial`;
  ctx.textAlign = 'center';
  ctx.fillText(initials, radius+5,radius*4/3+margin);
  return canvas.toDataURL();
}