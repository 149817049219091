import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput
} from 'react-admin';


const QuestionCategoryCreate = (props) => {
    return (
        <Create title='Stwórz kategorię pytania...'  {...props}>
            <SimpleForm redirect={'/question_categories'}>
                <TextInput source="title" label="Kategoria" />
                <NumberInput source={'questionCategoryOrder'} label={'Kolejność kategorii'}/>
                {/*<ReferenceArrayInput label="Pytania" reference="questions" source="questions">*/}
                {/*    <SelectArrayInput optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}
            </SimpleForm>

        </Create>
    )
}

export default QuestionCategoryCreate;