import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    DateField,
    BooleanField,
    NumberField,
    EditButton,
    DeleteButton,
    SelectField, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

 const QuestionnaireList = (props) => {

    return <List {...props} exporter={false}>
        <Datagrid>
            <TextField source='title' label="Nazwa Ankiety" />
            <DateField source='createdAt' label="Data dodania" />
            <DateField source='updatedAt' label="Data edycji" />
            <ReferenceArrayField label="Pakiety" reference="products" source="products">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <MyEditButton basePath='/questionnaire_templates' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/questionnaire_templates' label="Usuń"/>
        </Datagrid>
    </List>
}

export default QuestionnaireList;