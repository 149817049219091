import React, {useEffect, useState} from "react";
import {FormTab, SaveButton, TabbedForm, useDataProvider, useGetList} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {LinearProgress, TextField as TextFieldMUI, Toolbar} from "@material-ui/core";
import {useHistory, useLocation} from "react-router";
import {differenceWith, groupBy, isEmpty} from 'lodash'


const filterPrices = (prices, newClient, couple, partner, duration, diet, workout, handleChange, isPromoPrice) => {

    if (Object.values(prices).length == 0) return <></>

    const price = Object.values(prices).reduce((a, b) => a.concat(b), []).find(p =>
        p.duration === duration &&
        p.isDietOffer === diet &&
        p.isForCouple === couple &&
        (p.isReturningClient !== newClient || partner) &&
        (p.isForIndividualClient !== couple || p.isForIndividualClient !== partner) &&
        p.isDietOffer === diet &&
        p.isWorkoutOffer === workout &&
        p.isForPartner === partner)

    if (!price) return <></>

    return <TextFieldMUI
        type="number"
        InputLabelProps={{
            shrink: true,
        }}
        onChange={handleChange}
        name={price.id}
        value={isPromoPrice ? price.discountedPriceToMarketing / 100 : price.price / 100}
        variant="filled"
    />
}

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange'}
    },
});

const PricesToolbar = props => {
    const dataProvider = useDataProvider()
    const history = useHistory()

    const save = () => {
        const changes = differenceWith(Object.values(props.prices).reduce((a, b) => a.concat(b), []), Object.values(props.data), (l, r) => l.id == r.id && l.price == r.price && l.discountedPriceToMarketing == r.discountedPriceToMarketing)

        changes.forEach(async c => {
            await dataProvider.update('products', {id: c.id, data: {price: Math.round(c.price), discountedPriceToMarketing: Math.round(c.discountedPriceToMarketing)}})
        })

        setTimeout(() => props.refetch(), 400)

    }

    return <Toolbar {...props} redirect={false} handleSubmitWithRedirect={save}>
        <SaveButton
            handleSubmitWithRedirect={save}
            label="Zapisz"
            redirect={false}
            submitOnEnter={true}
        />
    </Toolbar>
};

const PriceList = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [couple, setCouple] = useState(false)
    const [onePerson, setOnePerson] = useState(true)
    const [partner, setPartner] = useState(false)
    const [prices, setPrices] = useState({})
    const [isPromoPrice, setIsPromoPrice] = useState(false)
    useEffect(() => {
        if (location.pathname.includes('new') || location.pathname.includes('returning')) return;

        history.push('/products/new')

    }, [location.pathname])


    const isNew = location.pathname.includes('new')

    const {data, ids, loading, error, refetch} = useGetList(
        'products',
        {page: 1, perPage: 50}
    );

    useEffect(() => {
        const grouped = groupBy(data, 'id')
        setPrices(grouped)
    }, [data])

    if (loading || isEmpty(data)) {
        return <LinearProgress/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    const handleChange = (e) => {
        const price = [...prices[e.target.name]]
        if (isPromoPrice) {
            setPrices({...prices, [e.target.name]: price.map(p => ({...p, discountedPriceToMarketing: Number(e.target.value) * 100}))
            })
        } else {
            setPrices({...prices, [e.target.name]: price.map(p => ({...p, price: Number(e.target.value) * 100}))})
        }
    }


    const handleValue = () => {
        if (couple) {
            return 'forCouple'
        } else if (partner) {
            return 'forPartner'
        } else if (onePerson) {
            return 'forOnePerson'
        }
    }
    const handleOnChange = (e) => {
        if (e.target.value === "forCouple") {
            setCouple(true)
            setPartner(false)
        }
        if (e.target.value === "forPartner") {
            setPartner(true)
            setCouple(false)
        }
        if (e.target.value === "forOnePerson") {
            setPartner(false)
            setCouple(false)
        }
        if (e.target.value === "systemPrice") {
            setIsPromoPrice(false)
        }
        if (e.target.value === "wpPrice") {
            setIsPromoPrice(true)
        }
    }

    return <>
        <select onChange={handleOnChange}
                style={{
                    padding: "10px",
                    margin: "10px 0 20px",
                    textTransform: "uppercase",
                    width: "256px",
                    marginLeft: "16px"
                }}
                name="promo-price"
                id="promo-price">
            <option value="systemPrice">Ceny używane w systemie</option>
            <option value="wpPrice">Cena promocyjna WP</option>
        </select>
        <TabbedForm undoable={false} redirect={false}
                    toolbar={<PricesToolbar data={data} refetch={refetch} prices={prices}/>}>
            <FormTab path={'new'} label={"Klient - nowy"}>
                <select value={handleValue()}
                        onChange={handleOnChange} id="plan-select" id="plan-select"
                        style={{padding: "10px", margin: "10px 0 20px", textTransform: "uppercase"}}>
                    <option value="forOnePerson">Plan dla jednej osoby</option>
                    <option value="forCouple">Plan dla par</option>
                    <option value="forPartner">Plan dla partnera</option>
                </select>
                <div className={"table"}>
                    <table>
                        <tr>
                            <th></th>
                            <th>6 tygodni</th>
                            <th>12 tygodni</th>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan żywieniowy + plan treningowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, true, true, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, true, true, handleChange, isPromoPrice)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan żywieniowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, true, false, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, true, false, handleChange, isPromoPrice)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan treningowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, false, true, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, false, true, handleChange, isPromoPrice)}</td>
                        </tr>
                    </table>
                </div>
            </FormTab>

            <FormTab path={'returning'} onClick={(e)=> setPartner(false)} label={"Klient - powracający"}>
                <select value={couple ? 'forCouple' : 'forOnePerson'}
                        onChange={e => setCouple(e.target.value === 'forCouple')} id="plan-select"
                        style={{padding: "10px", margin: "10px 0 20px", textTransform: "uppercase"}}>
                    <option value="forOnePerson">Plan dla jednej osoby</option>
                    <option value="forCouple">Plan dla par</option>
                </select>
                <div className={"table"}>
                    <table>
                        <tr>
                            <th></th>
                            <th>6 tygodni</th>
                            <th>12 tygodni</th>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan żywieniowy + plan treningowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, true, true, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, true, true, handleChange, isPromoPrice)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan żywieniowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, true, false, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, true, false, handleChange, isPromoPrice)}</td>
                        </tr>
                        <tr>
                            <th style={{textAlign: "left"}}>Plan treningowy</th>
                            <td>{filterPrices(prices, isNew, couple, partner, 6, false, true, handleChange, isPromoPrice)}</td>
                            <td>{filterPrices(prices, isNew, couple, partner, 12, false, true, handleChange, isPromoPrice)}</td>
                        </tr>
                    </table>
                </div>
            </FormTab>

        </TabbedForm>
    </>
}

export default PriceList;