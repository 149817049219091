import React, {useEffect, useState} from "react";
import {
    Admin,
    AppBar,
    getResources,
    Layout,
    Resource,
    RouteWithoutLayout,
    Sidebar,
    useAuthProvider, useGetIdentity,
    UserMenu
} from 'react-admin';
import {NavLink} from 'react-router-dom'
import {Redirect, Route, useLocation} from 'react-router'
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {useIntrospection} from "@api-platform/admin";
import {default as baseFetchHydra} from './providers/fetchHydra'
import {default as baseHydraDataProvider} from './providers/dataProvider'
import UserIcon from '@material-ui/icons/Person';
import UsersIcon from '@material-ui/icons/People';
import EuroIcon from '@material-ui/icons/Euro';
import ViewListIcon from '@material-ui/icons/ViewList';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AppleIcon from '@material-ui/icons/Apple';
import CategoryIcon from '@material-ui/icons/Category';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import ChatIcon from '@material-ui/icons/Chat';
import AddIcon from '@material-ui/icons/Add';
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
import UserCreate from './components/User/UserCreate';
import UserList from './components/User/UserList';
import UsersEdit from "./components/User/UserEdit";
import UserGroup from "./components/UserGroup/UserGroup";
import UserGroupEdit from "./components/UserGroup/UserGroupEdit";
import UserGroupCreate from "./components/UserGroup/UserGroupCreate";
import PriceList from "./components/Prices/PriceList";
import QuestionnaireList from "./components/Questionnaire/QuestionnaireList";
import QuestionnaireListEdit from "./components/Questionnaire/QuestionnaireListEdit";
import QuestionnaireListCreate from "./components/Questionnaire/QuestionnaireListCreate";
import OrderShow from "./components/OrderList/OrderShow";
import OrderList from "./components/OrderList/OrderList";
import OrderEdit from "./components/OrderList/OrderEdit";
import ProductList from "./components/Products/ProductList";
import ProductEdit from "./components/Products/ProductEdit";
import ProductCreate from "./components/Products/ProductCreate";
import MealList from "./components/Meals/MealList";
import MealEdit from "./components/Meals/MealEdit";
import MealCreate from "./components/Meals/MealCreate";
import ExerciseList from "./components/Exercises/ExerciseList";
import ExerciseCreate from "./components/Exercises/ExerciseCreate";
import ExerciseEdit from "./components/Exercises/ExerciseEdit";
import FaqList from "./components/Faq/FaqList";
import FaqCreate from "./components/Faq/FaqCreate";
import FaqEdit from "./components/Faq/FaqEdit";
import MeasureList from "./components/MeasureForm/MeasureList";
import TrainingList from "./components/Trainings/TrainingList";
import MeasureCreate from "./components/MeasureForm/MeasureCreate";
import MeasureEdit from "./components/MeasureForm/MeasureEdit";
import TrainingCreate from "./components/Trainings/TrainingCreate";
import TrainingEdit from "./components/Trainings/TrainingEdit";
import DietMaker from "./components/DietMaker/DietMaker";
import DietMakerEdit from "./components/DietMaker/DietMakerEdit";
import DietMakerCreate from "./components/DietMaker/DietMakerCreate";
import ClientImagesList from "./components/ClientImages/ClientImagesList";
import ClientImagesCreate from "./components/ClientImages/ClientImagesCreate";
import ClientImagesEdit from "./components/ClientImages/ClientImagesEdit";
import ClientMsgList from "./components/ClientMsg/ClientMsgList";
import Chat from "./components/ClientMsg/Chat";
import CouponList from "./components/Coupon/CouponList";
import CouponListCreate from "./components/Coupon/CouponListCreate";
import CouponListEdit from "./components/Coupon/CouponListEdit";
import QuestionAdd from "./components/QuestionAdd/QuestionAdd";
import QuestionAddEdit from "./components/QuestionAdd/QuestionAddEdit";
import QuestionAddCreate from "./components/QuestionAdd/QuestionAddCreate";
import AnswerEdit from "./components/Answer/AnswerEdit";
import AnswerCreate from "./components/Answer/AnswerCreate";
import {useSelector} from 'react-redux'
import {groupBy} from 'lodash'
import {Collapse, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles} from "@material-ui/core";
import {Accessibility, ExpandLess, ExpandMore} from '@material-ui/icons'
import authProvider from "./providers/authProvider";
import IngredientCategoryList from "./components/categories/IngredientCategoryList";
import IngredientCategoryCreate from "./components/categories/IngredientCategoryCreate";
import IngredientCategoryEdit from "./components/categories/IngredientCategoryEdit";
import DishCategoryList from "./components/categories/DishCategoryList";
import DishCategoryCreate from "./components/categories/DishCategoryCreate";
import DishCategoryEdit from "./components/categories/DishCategoryEdit";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import polishMessages from "./translation/polishMessages";
import MuscleList from "./components/Muscles/MuscleList";
import MusclesCreate from "./components/Muscles/MusclesCreate";
import MusclesEdit from "./components/Muscles/MusclesEdit";
import ClientCreate from "./components/Clients/ClientCreate";
import ClientEdit from "./components/Clients/ClientEdit";
import ClientList from "./components/Clients/ClientList";
import QuestionCategoryList from "./components/categories/QuestionCategoryList";
import QuestionCategoryCreate from "./components/categories/QuestionCategoryCreate";
import QuestionCategoryEdit from "./components/categories/QuestionCategoryEdit";
import TemplateList from "./components/Templates/TemplateList";
import TemplateCreate from "./components/Templates/TemplateCreate";
import TemplateEdit from "./components/Templates/TemplateEdit";
import IngredientDishCreate from "./components/IngredientDish/IngredientDishCreate";
import CustomCloneButton from "./components/Meals/CustomCloneLayout";
import IngredientDishEdit from "./components/IngredientDish/IngredientDishEdit";
import ClientMsgCreate from "./components/ClientMsg/ClientMsgCreate";
import TrainingSchemaList from "./components/TrainingSchema/TrainingSchemaList";
import TrainingSchemaEdit from "./components/TrainingSchema/TrainingSchemaEdit";
import TrainingSchemaCreate from "./components/TrainingSchema/TrainingSchemaCreate";
import Permissions from "./components/Permissions";
import FlashList from "./components/flash/FlashList";
import FlashListEdit from "./components/flash/FlashListEdit";
import FlashListCreate from "./components/flash/FlashListCreate";
import QuestionnaireClientList from "./components/Questionnaire/QuestionnaireClientList";
import QuestionnaireClientShow from "./components/Questionnaire/QuestionnaireClientShow";
import {CircularProgress} from "@mui/material";
import jwt_decode from "jwt-decode";
import LogRocket from "logrocket";


const messages = {
    'pl': polishMessages
};


const i18nProvider = polyglotI18nProvider(() => polishMessages, 'pl');

const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#f1562f',
            background: '#ffa84c'
        },
        secondary: {
            main: '#ffa84c'
        }
    },
    navbar: {
      primary: '#ffa84c'
    },
    header: {
      backgroundColor: "#ff0000"
    },
    sidebar: {
      width: 300,
        backgroundColor: '#000000',
       drawerPaper: {
          backgroundColor: '#000000',
       }
    }
})

const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
            src="https://marmelab.com/images/avatars/adrien.jpg"
        />
    )
};

const useSidebarStyles = makeStyles({
    drawerPaper: {
        backgroundColor: '#2F3438',
        color: '#ffffff',
    },
});

const MySidebar = props => {


    // const classes = useSidebarStyles();
    return (
        <Sidebar {...props} />
    );
};

const MyUserMenu = props => {
    return <UserMenu {...props} icon={<MyCustomIcon />} />
};

const useMenuStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    group: {
        fontWeight: 'bold',
    },
    active: {
        fontWeight: 'bold',
        color: '#f4a84c'
    }
}));

function NestedList({value, open, setOpen, keyVal}) {
    const classes = useMenuStyles();
    const Icon = 'icon' in value[0].options ? value[0].options.icon : () => <></>
    const location = useLocation();

    const groupedRes = groupBy(value.filter(r => r.options.label), 'options.subGroup')
    const [openSub, setOpenSub] = useState(false)

    return <><ListItem className={classes.group} button onClick={() => setOpen(open === keyVal? null: keyVal)}>
        <ListItemIcon>
            <Icon/>
        </ListItemIcon>
        <ListItemText  classes={{
            primary: classes.group
        }} primary={keyVal} />
        {open === keyVal ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
        <Collapse in={open === keyVal} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {Object.entries(groupedRes).map(([key,value]) => {
                    if (key == 'undefined') {
                        return value.map((item, i) => <ListItem component={NavLink} to={'/'+item.name} className={classes.nested} key={i} button >
                            <ListItemIcon>
                                <item.icon />
                            </ListItemIcon>
                            <ListItemText classes={{
                                primary: location.pathname.includes(item.name)? classes.active:''
                            }} primary={item.options.label} />
                        </ListItem>)
                    } else {
                        return <>
                        <ListItem className={classes.nested} button onClick={() => setOpenSub(openSub === key? null: key)}>
                            <ListItemIcon>
                                <Icon/>
                            </ListItemIcon>
                            <ListItemText  classes={{
                                primary: classes.group
                            }} primary={key} />
                            {openSub === key ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={openSub === key} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {value.map((item, i) =><> <ListItem component={NavLink} to={'/'+item.name} className={classes.nested} key={i} button>
                                        <ListItemIcon>
                                            <item.icon />
                                        </ListItemIcon>
                                        <ListItemText classes={{
                                            primary: location.pathname.includes(item.name)? classes.active:''
                                        }} primary={item.options.label} />
                                    </ListItem>

                                    </>
                                    )}

                                </List>
                            </Collapse>
                        </>
                    }
                })}
            </List>
        </Collapse>
    </>

}

const MyMenu = props => {
    const resources = useSelector(getResources);
    const identity = useGetIdentity()
    const [groupedRes, setGroupedRes] = useState({})

    const classes = useMenuStyles();

    useEffect(() => {
        if(!identity.loading && identity.identity) {
            try{
                setGroupedRes(groupBy(resources.filter(r => r.options.label).filter(r => !identity?.identity || jwt_decode(identity?.identity)?.userGroupAccess[r.options?.permission]), 'options.group'))
            } catch (e) {
                setGroupedRes({})
            }
        } else {
            setGroupedRes(groupBy(resources.filter(r => r.options.label), 'options.group'))
        }

    }, [identity, resources])


    const location = useLocation();
    const [open, setOpen] = useState(null)


    return identity?.loading? <CircularProgress/>:<div><List
        component="nav"
        className={classes.root}
    >
        {Object.entries(groupedRes).map(([key, val]) => {
            if(key == 'undefined'){
                return val.map((item, i) => <ListItem key={i} button component={NavLink} to={'/'+item.name}>
                    <ListItemIcon>
                        <item.icon />
                    </ListItemIcon>
                    <ListItemText classes={{
                        primary: location.pathname.includes(item.name)? classes.active + ' ' +classes.group:classes.group
                    }} primary={item.options.label} />
                </ListItem>)
            } else {
                return <NestedList open={open} setOpen={setOpen} key={key} value={val} keyVal={key} resources={resources} groupedRes={groupedRes}/>
            }
        })}
    </List></div>
}

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomAppBar = withStyles(styles)(({ classes, userMenu, ...props }) => {
    const authProvider = useAuthProvider();
    return (
        <AppBar userMenu={userMenu || !!authProvider} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
        </AppBar>
    );
});


const MyLayout = props => <Layout {...props} appBar={CustomAppBar} menu={MyMenu} />


const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

export const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });


const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
        return { api };
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin} />
                ],
            };
        }

        throw result;
    }
};

export const dataProviderHydra = baseHydraDataProvider(process.env.REACT_APP_API_URL + "/api", fetchHydra, apiDocumentationParser);



function App() {
    const [loaded, setLoaded] = useState(false)

    authProvider.checkAuth().then(() => setTimeout(() => dataProviderHydra.introspect().then(() => setLoaded(true)), 200)).catch(() => {
        setLoaded(true)
    })

    useEffect(() => {
        if (loaded && process.env.NODE_ENV === 'production') {
            LogRocket.init('ytmlva/hb');
        }
    }, [loaded])

  return loaded? <Admin
      i18nProvider={i18nProvider}
      locale="pl" messages={messages}
      customRoutes={[
          <Route path="/products" component={PriceList} />,
          <RouteWithoutLayout noLayout={true} path="/permissions" component={Permissions} />,
          <RouteWithoutLayout
              noLayout={true}
              path="/oko"
              component={CustomCloneButton}/>
      ]}
      loading={loaded} theme={theme} authProvider={authProvider} layout={MyLayout} dataProvider={dataProviderHydra}>

      <Resource options={{permission: 'isUsersAccess', label: 'Administratorzy', group: 'Administratorzy', icon: UserIcon}} name='users' list={UserList} create={UserCreate} edit={UsersEdit} icon={UserIcon}/>
      <Resource options={{permission: 'isClientsAccess', label: 'Klienci'}} name='clients' list={ClientList}  edit={ClientEdit} create={ClientCreate} icon={ViewListIcon}/>
      <Resource options={{permission: 'isGroupsAccess', label: 'Grupy administratorów', group: 'Administratorzy', icon: UserIcon}} name='groups' list={UserGroup} create={UserGroupCreate} edit={UserGroupEdit} icon={UsersIcon}/>
      <Resource options={{permission: 'isQuestionnaireAccess', label: 'Ankiety', group: 'Ankiety', icon: UserIcon, subGroup: 'Tworzenie Ankiet'}} name='questionnaire_templates' list={QuestionnaireList} create={QuestionnaireListCreate} edit={QuestionnaireListEdit} icon={QuestionAnswerIcon}/>
      <Resource options={{permission: 'isClientsQuestionnaireAccess', label: 'Ankiety użytkowników', group: 'Ankiety', icon: UserIcon}} name='questionnaires' list={QuestionnaireClientList} show={QuestionnaireClientShow} icon={QuestionAnswerIcon}/>
      <Resource options={{permission: 'isQuestionnaireAccess', label: 'Kategoria pytań', group: 'Ankiety', icon: UserIcon, subGroup: 'Tworzenie Ankiet'}} name='question_categories' list={QuestionCategoryList} create={QuestionCategoryCreate} edit={QuestionCategoryEdit} icon={QuestionAnswerIcon}/>
      <Resource options={{permission: 'isDishesAccess', label: 'Produkty', group: 'Dieta', icon:FastfoodIcon}} name='ingredients' list={ProductList} create={ProductCreate} edit={ProductEdit} icon={AppleIcon}/>
      <Resource options={{permission: 'isDishesAccess', label: 'Kategoria zamienników', group: 'Dieta', icon:FastfoodIcon}} name='ingredient_categories' list={IngredientCategoryList} create={IngredientCategoryCreate} edit={IngredientCategoryEdit} icon={CategoryIcon}/>
      <Resource options={{permission: 'isExercisesAccess', label: 'Partie mięśni', group: 'Trening', icon: FitnessCenterIcon}} name='muscles' list={MuscleList} create={MusclesCreate} edit={MusclesEdit} icon={FitnessCenterIcon}/>
      <Resource options={{permission: 'isExercisesAccess', label: 'Ćwiczenia', group: 'Trening', icon: FitnessCenterIcon}} name='exercises' list={ExerciseList} create={ExerciseCreate} edit={ExerciseEdit} icon={FitnessCenterIcon}/>
      <Resource options={{permission: 'isMeasurementFormsAccess', label: 'Pomiary', group: 'Sylwetka', icon: Accessibility}} name='measurement_forms' list={MeasureList} create={MeasureCreate} edit={MeasureEdit} icon={ViewListIcon}/>
      <Resource options={{permission: 'isProductsAccess', label: 'Ceny pakietów'}} name='products' list={() => <Redirect to={'/products/new'}/>} icon={EuroIcon} label={"asdsad"}/>
      <Resource options={{permission: 'isPurchasesAccess', label: 'Zamówienia'}} name='purchases' show={OrderShow} list={OrderList} edit={OrderEdit} icon={ViewListIcon}/>
      <Resource options={{permission: 'isQuestionnaireAccess', label: 'Pytania', group: 'Ankiety', subGroup: 'Tworzenie Ankiet'}} name='questions' list={QuestionAdd} create={QuestionAddCreate} edit={QuestionAddEdit} icon={QuestionAnswerIcon}/>
      <Resource name="answers" edit={AnswerEdit} create={AnswerCreate} />
      <Resource name="ingredient_dishes" create={IngredientDishCreate} edit={IngredientDishEdit}/>
      <Resource name="diet_dish_lunches" />
      <Resource name="diet_dish_second_lunches" />
      <Resource name="diet_dish_second_breakfasts" />
      <Resource name="diet_dish_breakfasts" />
      <Resource name="diet_dish_dinners" />
      <Resource name="diet_dish_desserts" />
      <Resource name="diet_dish_snacks" />
      <Resource name="attachments" />
      <Resource name="messages" />
      <Resource options={{permission: 'isDishesAccess', label: 'Potrawy', group: 'Dieta', icon:FastfoodIcon}} name='dishes' list={MealList} create={MealCreate} edit={MealEdit} icon={FastfoodIcon}/>
      <Resource options={{permission: 'isDishesAccess', label: 'Kategoria potraw', group: 'Dieta', icon:FastfoodIcon}} name='dish_categories' list={DishCategoryList} create={DishCategoryCreate} edit={DishCategoryEdit} icon={FastfoodIcon}/>
      <Resource options={{permission: 'isExercisesAccess', label: 'Tworzenie treningów', group: 'Trening', icon: FitnessCenterIcon}} name='workouts' list={TrainingList} create={TrainingCreate} edit={TrainingEdit} icon={FitnessCenterIcon}/>
      <Resource options={{permission: 'isFAQAccess', label: 'FAQ'}} name='faqs' list={FaqList} create={FaqCreate} edit={FaqEdit} icon={ViewListIcon}/>
      <Resource options={{permission: 'isDishesAccess', label: 'Tworzenie diet', group: 'Dieta'}} name='diets' list={DietMaker} create={DietMakerCreate} edit={DietMakerEdit} icon={AddIcon}/>
      <Resource options={{permission: 'isSilhouettePhotosAccess', label: 'Zdjęcia sylwetki', group: 'Sylwetka'}} name='silhouettes_photos' list={ClientImagesList} create={ClientImagesCreate} edit={ClientImagesEdit} icon={ViewListIcon}/>
      <Resource options={{permission: 'isChatAccess', label: 'Wiadomości od klientów'}} show={Chat} name='conversations' list={ClientMsgList} create={ClientMsgCreate} icon={ChatIcon}/>
      <Resource options={{permission: 'isCouponsAccess', label: 'Kupony'}} name='discount_coupons' list={CouponList} create={CouponListCreate} edit={CouponListEdit}  icon={ChatIcon}/>
      <Resource options={{permission: 'isDishesAccess', label: 'Szablony diet', group: 'Dieta', icon:FastfoodIcon}} name='diet_templates' list={TemplateList} create={TemplateCreate} edit={TemplateEdit} icon={CategoryIcon}/>
      <Resource options={{permission: 'isExercisesAccess', label: 'Szablony treningów', group: 'Trening', icon:FitnessCenterIcon}} name='workout_templates' list={TrainingSchemaList} create={TrainingSchemaCreate} edit={TrainingSchemaEdit} icon={CategoryIcon}/>
      <Resource options={{permission: 'isFlashMessageAccess', label: 'Wiadomość Flash'}} name='news' list={FlashList} edit={FlashListEdit} create={FlashListCreate}
                icon={ViewListIcon}/>
  </Admin>:<></>
}

export default App;
