import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput, DateField, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, ReferenceInput
} from 'react-admin';


const MeasureEdit = (props) => {
    return (
        <Edit title='Edytuj...'  {...props}>
            <SimpleForm redirect={'/measurement_forms'}>
                <ReferenceInput source="client" reference="clients" label="Wybór użytkownika">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateInput source='measurementDate' label="Data pomiaru" defaultValue={new Date}/>
                <TextInput source="title" label="Nazwa pomiaru" />
                <NumberInput source='clientBodyWeight' label="Masa ciała"/>
                <NumberInput source='clientChestSize' label="Obwód klatki pier."/>
                <NumberInput source='clientWaistSize' label="Obwód pasa"/>
                <NumberInput source='clientHipSize' label="Obwód bioder"/>
                <NumberInput source='clientThighSize' label="Obwód uda"/>
                <NumberInput source='clientArmSize' label="Obwód ramienia"/>
            </SimpleForm>

        </Edit>
    )
}

export default MeasureEdit;