import React from "react";
import {Edit, SelectInput, SimpleForm, TextInput} from 'react-admin';
import PasswordCustomInput from "../Fields/PasswordCustomInput";

const ClientEdit = (props) => {
    return (
        <Edit title='Edycja klienta'  {...props}>
            <SimpleForm redirect={'/clients'}>
                <TextInput source='name' label="Imię"/>
                <TextInput source='surname' label="Nazwisko" />
                <TextInput source='email' label="Email"/>

                <SelectInput source='isActive' label="Dostęp" choices={[
                    {id: true, name: 'Tak'},
                    {id: false, name: 'Nie'}
                ]}/>
                <PasswordCustomInput source={'password'} label={'Hasło'} />
            </SimpleForm>

        </Edit>
    )
}

export default ClientEdit;