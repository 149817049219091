import React from "react";
import {
    Edit,
    Create,
    NumberInput,
    SaveButton,
    Toolbar,
    SelectInput,
    DeleteButton,
    ReferenceArrayInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceArrayField,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    EditButton,
    ArrayInput,
    SimpleFormIterator, BooleanInput,
} from "react-admin";
import CountField from "../../components/Fields/CountField";
import QuestionInput from "../Fields/QuestionInput";
// import { GoBackToolbar } from "../Programs/ProgramEdit";

function QuestionAddCreate({ onCancel, ...props }) {

    const transform = async data => {

        data.answers = data.answers?.map((d, i) => ({...d, answerOrder:i}))
        return data
    }

    return (
        <Create {...props} >
            <SimpleForm redirect={"/questions"}>
                <TextInput source="question" label={"Pytanie"} />
                <NumberInput label={"Kolejność"} source={"questionOrder"} />
                <ReferenceInput label="Kategoria pytań" source="questionCategory" reference="question_categories">
                    <SelectInput required={true} optionText="title" />
                </ReferenceInput>

                <QuestionInput/>


                {/*<ReferenceArrayField*/}
                {/*    label="Odpowiedzi"*/}
                {/*    reference="answers"*/}
                {/*    source="answers"*/}
                {/*>*/}

                {/*    <Datagrid>*/}
                {/*        <TextField label="Odpowiedź" source={"answer"} />*/}
                {/*        <NumberField label="Kolejność" source={"order"} />*/}

                {/*        <EditButton />*/}
                {/*    </Datagrid>*/}
                {/*</ReferenceArrayField>*/}
            </SimpleForm>
        </Create>
    );
}

export default QuestionAddCreate;
