import React from "react";
import {CloneButton, Datagrid, EditButton, Filter, List, TextField, TextInput} from 'react-admin';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";


const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label="Szukaj po nazwie" source="templateName" alwaysOn />
    </Filter>
)

const TemplateList = (props) => {

    return <List
        exporter={false}
        filters={<SearchFilter />}
        {...props}>
        <Datagrid>

            <TextField source="templateName" label="Nazwa szablonu"/>
            <TextField source='dietName' label="Nazwa diety"/>

            <EditButton basePath='/diet_templates' label="Edytuj"/>
            <CloneButton basePath='/diet_templates'/>
            <DeleteButtonWithConfirmation basePath='/diet_templates' label="Usuń"/>
        </Datagrid>
    </List>
}
export default TemplateList;


