import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, RichTextField, EditButton, DeleteButton, NumberField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';


const CouponListCreate = (props) => {
    return (
        <Create undoable={false} title='Nowy kupon'  {...props}>
            <SimpleForm undoable={false} redirect={"/discount_coupons"}>
                {/*<NumberInput source='code' label="Numer kuponu" />*/}
                <TextInput required={true} source='code' label="Numer kuponu"/>
                <span>Cena podana w groszach</span>
                <NumberInput source='amountOfDiscount' label="Kwota zniżki" />
                <NumberInput source='limitOfUsage' label="Ilość użyć" />
                {/*<ReferenceArrayInput source="user" reference="users" label="Osoba, która wygenrowała kupon">*/}
                {/*    <SelectArrayInput optionText="name" />*/}
                {/*</ReferenceArrayInput>*/}
                <SelectInput source="status" choices={[
                    {id: 0, name: 'Aktywny'},
                    {id: 1, name: 'Nieaktywny'},
                ]}/>

                {/*<EditButton basePath='/coupon' label="Edytuj"/>*/}
                {/*<DeleteButton basePath='/coupon' label="Usuń" />*/}
            </SimpleForm>

        </Create>
    )
}

export default CouponListCreate;