import React, {useEffect, useState} from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    ReferenceArrayField,
    ReferenceField,
    ShowButton,
    SingleFieldList,
    TextField,
    NumberField, TextInput, useRecordContext, useGetList, SelectField, SelectInput, useDataProvider

} from 'react-admin';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";
import {Checkbox} from "@material-ui/core";

// {
//  "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
//     "owner": "../словник",
//     "purchase": "../словник",
//     "questions": [],
//     "questionsCount": 0
// }

const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label="Id klienta" source="owner.helperId" alwaysOn/>
        <DateInput label="Data" source="purchase.expiresAt[after]" alwaysOn/>
    </Filter>

);

function CheckboxField(props) {
    const {source, status} = props;
    const record = useRecordContext(props);
    const dateProvider = useDataProvider();

    const changeStatus = (e) => {
        dateProvider.update('questionnaires', {id: record.id, data: {status: !e.target.checked ? record[source] === 2? status===1? 0:1:0:status}})
    }
    return (
        <>
            <Checkbox onChange={changeStatus} defaultChecked={record[source]} checked={record[source] === 2 || record[source] === status}/>
        </>
    )
}

const QuestionnaireClientList = (props) => {
    return <List {...props} syncWithLocation={Boolean(props.location.search)} filters={<SearchFilter/>} exporter={false}>
        <Datagrid>
            <ReferenceField label="Imie i nazwisko" reference="clients" source="owner">
                <FunctionField render={record => `${record.name} ${record.surname}`}/>
            </ReferenceField>
            <ReferenceField label="E-mail" reference="clients" source="owner">
                <FunctionField render={record => record.notifyEmail? record.notifyEmail: record.email}/>
            </ReferenceField>
            <ReferenceField label="ID klienta" reference="clients" source="owner">
                <TextField source={"helperId"}/>
            </ReferenceField>
            <ReferenceField label="Pakiety" reference="purchases" source="purchase">
                            <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Data wygaśnięcia" reference="purchases" source="purchase" sortBy="purchase.expiresAt">
                <DateField source="expiresAt" showTime/>
            </ReferenceField>
            <DateField source={"createdAt"} options={{dateStyle: "short", timeStyle: "short"}} label={"Data i godzina dodania ankiety"} showTime/>
            <SelectField source={"status"} choices={[
                {id: 0, name: "Nowa"},
                {id: 1, name: "Sprawdzona"},
                {id: 2, name: "Zakończona"},
            ]}/>
            <CheckboxField label={"Sprawdzona"} source={"status"} status={1}/>
            <CheckboxField label={"Zakończona"} source={"status"} status={2}/>
            <ShowButton/>
        </Datagrid>
    </List>
}

export default QuestionnaireClientList;