import React from "react";
import { NumberInput, Edit, SimpleForm, TextInput } from "react-admin";
// import { GoBackToolbar } from "../Programs/ProgramEdit";

function AnswerEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm redirect={'/questions'}>
        <TextInput source={"answer"} label={"Odpowiedź"} />
        <NumberInput label={"Kolejność"} source={"order"} />
      </SimpleForm>
    </Edit>
  );
}

export default AnswerEdit;
