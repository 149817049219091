import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField, RichTextField, EditButton, DeleteButton, NumberField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';


const FlashListCreate = (props) => {
    return (
        <Create title='Nowy kupon'  {...props}>
            <SimpleForm redirect={"/news"}>
                <TextInput multiline source='newsContent' label="Treść wiadomości"/>
                <NumberInput source='newsOrder' label="Kolejność"/>

                {/*<EditButton basePath='/coupon' label="Edytuj"/>*/}
                {/*<DeleteButton basePath='/coupon' label="Usuń" />*/}
            </SimpleForm>

        </Create>
    )
}

export default FlashListCreate;