import React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput, ReferenceArrayField, ReferenceField
} from 'react-admin';
import CustomArrayImageField, {CustomImageField} from "../Fields/CustomArrayImageField";


const MusclesEdit = (props) => {
    return (
        <Edit title='Edytuj partię mięśni...'  {...props}>
            <SimpleForm redirect={'/muscles'}>
                <TextInput source="muscleName" label="Partia mięśni" />
                <TextInput source="muscleDescription" label="Opis" />
                <ReferenceArrayInput label="Ćwiczenia" reference="exercises" source="exercises" className={"custom-exercises"}>
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>
                <ReferenceInput label={"Zdjęcia potrawy"} source="photo" reference="attachments">
                    <CustomImageField name={'photo'} />
                </ReferenceInput>
            </SimpleForm>

        </Edit>
    )
}

export default MusclesEdit;