import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    SelectInput,
    SelectField,
    ArrayField,
    SingleFieldList, ReferenceArrayField, ChipField, TextInput, CloneButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const ExerciseFilter = [
   <TextInput label={"Wyszukaj po nazwie"} source={"name"} resettable alwaysOn/>
]

 const ExerciseList = (props) => {
    return <List {...props} exporter={false} filters={ExerciseFilter}>
        <Datagrid>
            <TextField source='name' label="Nazwa ćwiczenia"/>
            <TextField source={"exerciseCode"} label={"Kod ćwiczenia"}/>
            <ReferenceArrayField label="Partia mięśni" reference="muscles" source="muscles">
                <SingleFieldList>
                    <ChipField source="muscleName" label="Kategoria" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source='createdAt' label="Data dodania"/>
            <MyEditButton basePath='/exercises' label="Edytuj"/>
            <CloneButton basePath='/exercises' label="Sklonuj"/>
            <DeleteButtonWithConfirmation basePath='/exercises' label="Usuń"/>
        </Datagrid>
    </List>
}

export default ExerciseList;