import {Create, List,BooleanInput, SimpleForm, NumberInput, TextField, TextInput, SelectInput} from "react-admin";
import React from "react";


const UserCreate = (props) => {
    return <Create title='Nowa grupa użytkowników' {...props}>
        <SimpleForm redirect={'/groups'}>
            <TextInput source={'title'} label='Nazwa grupy'/>
            <BooleanInput label="Użytkownicy" source="isUsersAccess" />
            <BooleanInput label="Grupy użytkowników" source="isGroupsAccess" />
            <BooleanInput label="Klienci" source="isClientsAccess" />
            <BooleanInput label="Dania" source="isDishesAccess" />
            <BooleanInput label="Ankiety" source="isQuestionnaireAccess" />
            <BooleanInput label="Ankiety klientów" source="isClientsQuestionnaireAccess" />
            <BooleanInput label="Zamówienia" source="isPurchasesAccess" />
            <BooleanInput label="Ceny produktów" source="isProductsAccess" />
            <BooleanInput label="Kupony rabatowe" source="isCouponsAccess" />
            <BooleanInput label="Ćwiczenia" source="isExercisesAccess" />
            <BooleanInput label="FAQ" source="isFAQAccess" />
            <BooleanInput label="Wiadomości flash" source="isFlashMessageAccess" />
            <BooleanInput label="Formularze pomiarów" source="isMeasurementFormsAccess" />
            <BooleanInput label="Zdjęcia sylwetki" source="isSilhouettePhotosAccess" />
            <BooleanInput label="Chat" source="isChatAccess" />
        </SimpleForm>
    </Create>
}
export  default UserCreate;