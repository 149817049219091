import {
    BooleanField, Button,
    Create,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput, Toolbar, useDataProvider
} from "react-admin";
import React from "react";
import RichTextInput from "ra-input-rich-text";
import {CardioInput} from "./TrainingSchemaEdit";
import {useHistory} from "react-router";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";

const GoBackToolbar = (props) => {
    const history      = useHistory();
    const { values }   = useFormState();
    const dataProvider = useDataProvider()

    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []

    const save = () => {

        delete values['orginId']
        delete values['@id']

        Promise.all(tasks).then(() => {
            dataProvider.create('workout_templates', {
                data: {
                    ...values,
                }
            }).then(() => onSuccess())
        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};

const TrainingSchemaCreate = (props) => {
    return <Create  title='Nowy szablon treningu' {...props}>
        <SimpleForm undoable={false} redirect={'/workout_templates'} toolbar={<GoBackToolbar />}>
            <TextInput source='templateName' label="Nazwa szablonu"/>
            <TextInput source='workoutName' label="Nazwa treningu" />
            <RichTextInput source='shortWorkoutDescription' label="Rodzaj i krótki opis treningu"/>
            <RichTextInput source='trainingDays' label={'Ilość dni treningowych'}/>
            <RichTextInput source='trainingStaff' label="Sprzęt do ćwiczeń"/>
            <RichTextInput source='trainingParts' label="Podział treningu"/>
            <CardioInput/>
        </SimpleForm>
    </Create>
}
export  default TrainingSchemaCreate;