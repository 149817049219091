import React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    DeleteButton,
    SearchInput,
    Filter
}
    from 'react-admin';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";

const GroupFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="title" resettable alwaysOn/>
    </Filter>
)

const UserGroup = (props) => {
    return <List {...props} filters={<GroupFilter />} exporter={false}>
        <Datagrid>
            <TextField source='title' label={'Nazwa grupy'}/>
            <NumberField source={'userCount'} label='Ilość użytkowników'/>
            <EditButton basePath='/groups' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/groups' label="Usuń"/>
        </Datagrid>
    </List>
}
export default UserGroup;


