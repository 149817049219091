import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    SingleFieldList,
    ReferenceArrayField,
    ReferenceField,
    ChipField,
    TextInput,
    ReferenceInput,
    SelectInput,
    Filter, SearchInput, CloneButton, CreateButton,TopToolbar
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Add, FileCopy} from "@material-ui/icons";


const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};


const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label="Szukaj po kodzie" source="dishCode" alwaysOn />
        <TextInput label="Szukaj po nazwie" source="title" alwaysOn />
    </Filter>
)

export const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const CreateRelatedCommentButton = ({ record }) => {
  return  <Button
      style={{color: "#f1562f", fontSize: "13px"}}
        component={Link}
        to={{
            pathname: '/dishes/create',
            state: {record: {
                    ...record,
                    dietsBreakfasts: undefined,
                    dietsSecondBreakfasts: undefined,
                    dietsLunches: undefined,
                    dietsSecondLunches: undefined,
                    dietsDinners: undefined,
                    dietsDesserts: undefined,
                    dietsSnacks: undefined,
                    substitutes: undefined,
                    originId: undefined,
                    dietsBreakfastCount: undefined,
                    dietsSecondBreakfastsCount: undefined,
                    dietsLunchesCount: undefined,
                    dietsSecondLunchesCount: undefined,
                    dietsDinnersCount: undefined,
                    dietsDessertsCount: undefined,
                    dietsSnacksCount: undefined,
                    dietsCount: undefined,
                }
            },
        }}
    >
        <FileCopy/>

    </Button>
}



 const MealList = (props) => {

    return  <List {...props} filters={<SearchFilter />} actions={<ListActions/>}>
        <Datagrid>
            <TextField source="title" label="Nazwa potrawy" />
            <TextField source="dishCode" label="Kod potrawy" style={{width: "600px"}}/>
            <NumberField source='calculatedDishFats' label="Tłuszcze"/>
            <NumberField source='calculatedDishCarbohydrates' label="Węglowodany"/>
            <NumberField source='calculatedDishProteins' label="Białko"/>
            <NumberField source='calculatedDishCalorific' label="Ilość kalorii"/>
            {/*<ReferenceArrayField label="Produkty" reference="ingredient_dishes" source="ingredientsForDishes">*/}
            {/*    <SingleFieldList>*/}
            {/*        <ChipField source="id" />*/}
            {/*    </SingleFieldList>*/}
            {/*</ReferenceArrayField>*/}
            {/*<NumberField source='dietsCount' label="Ilość użycia w dietach"/>*/}
            {/*<DateField source='createdAt' label="Data dodania"/>*/}
            <EditButton basePath='/dishes' label=""/>
            <CreateRelatedCommentButton />
            {/*<CloneButton/>*/}
            <DeleteButtonWithConfirmation basePath='/dishes' label="" />
        </Datagrid>
    </List>
}

export default MealList;