import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput,
    DateField,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    AutocompleteInput,
    ArrayInput,
    ReferenceArrayField,
    Datagrid,
    EditButton,
    AutocompleteArrayInput,
    SingleFieldList, ChipField, ReferenceField, useRecordContext, useList, useGetList, Toolbar, SaveButton,Create
} from 'react-admin';
import {CustomImageField} from "../Fields/CustomArrayImageField";
import FilteredSelectField from "../Fields/CustomSelectField";
import CustomReferenceArrayInput from "../Fields/CustomReferenceArrayInput";
import RichTextInput from "ra-input-rich-text";
import {useHistory} from "react-router";
import { Button } from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {GoBackToolbar} from "../IngredientDish/IngredientDishCreate";

// export const GoBackToolbar = (props) => {
//     const history = useHistory();
//
//     const onSuccess = () => {
//         history.goBack();
//     };
//
//     return (
//         <Toolbar {...props}>
//             <SaveButton
//                 label="Zapisz"
//                 redirect={false}
//                 onClick={onSuccess}
//                 submitOnEnter={true}
//             />
//             <Button onClick={onSuccess}>Wstecz</Button>
//         </Toolbar>
//     );
// };

export const ProductCreateButton = () => {
    const history = useHistory()
    const record = useRecordContext()

    const redirect = () => {
        history.push({pathname: '/ingredient_dishes/create', state: {dishId: record.id}})
    }

    return <Button onClick={redirect}><Add/>Nowy produkt</Button>
}

const MealEdit = (props) => {

    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'ingredients',
        {page:1, perPage:1000}
    );

    const choices = (el) => {

        return Object.values(el).map( (e) => ({
            id: e.id,
            name: e.title
        }))
    }

    return (
        <Create title='Edytuj posiłek...'  {...props}>
            <SimpleForm toolbar={<GoBackToolbar />} undoable={false} keepDirtyOnReinitialize={true } redirect={'/dishes'}>
                <TextInput  source="title" label="Nazwa potrawy" />
                <TextInput  source="dishCode" label="Kod potrawy" />


                <ReferenceInput label={"Zdjęcia potrawy"} source="photo" reference="attachments">
                    <CustomImageField  name={'photo'} />
                </ReferenceInput>

                <ReferenceArrayInput label="Kategoria" source="categories" reference="dish_categories">
                    <SelectArrayInput optionText="title" />
                </ReferenceArrayInput>

                <ProductCreateButton/>
                <ReferenceArrayField
                    label="Produkty"
                    reference="ingredients"
                    source="ingredientsForDishes"
                >
                    <Datagrid>
                        <IngredientNames/>
                        <DeleteButton undoable={false} redirect={false}/>
                    </Datagrid>
                </ReferenceArrayField>

                {/*<ArrayInput source="ingredientsForDishes" label={""}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <AutocompleteInput label={"Produkty"} source="ingredient" choices={choices(data)}/>*/}
                {/*        <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>*/}
                {/*        <NumberInput source={"grams"} label={"Ilość gram"}/>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                {/*<ReferenceArrayField source="ingredientsForDishes" reference="ingredient_dishes" label="Produkt">*/}

                {/*        <AutocompleteArrayInput optionText="ingredient" />*/}


                {/*</ReferenceArrayField>*/}

                {/*<ReferenceArrayInput*/}
                {/*    label="Prodkuktyt"*/}
                {/*    reference="ingredients_dishes"*/}
                {/*    source="ingredientsForDishes"*/}
                {/*>*/}

                {/*    <Datagrid>*/}
                {/*        /!*<Label/>*!/*/}
                {/*        <EditButton redirect={"/xD"} />*/}
                {/*        <DeleteButton redirect={false}/>*/}
                {/*    </Datagrid>*/}
                {/*</ReferenceArrayInput>*/}

                {/*<ArrayInput source="ingredientsForDishes" label={"Dodaj produkt"}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredient" reference="ingredients" label="Produkt">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*        <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>*/}
                {/*        <NumberInput source={"grams"} label={"Ilość gram"}/>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                <RichTextInput multiline source="recipe" label="Przepis na potrawę" />

                {/*<ArrayInput source="ingredientsForDishes" label={"Dodaj produkt"}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput source="ingredientsForDishes" reference="ingredients" label="Produkt">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*        /!*<NumberInput source={"grams"} label={"Ilość gram"}/>*!/*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}

                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <FilteredSelectField  optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                <NumberInput source='approximatePrepareTime' label="Czas przygotowywania"/>

            </SimpleForm>
        </Create>
    )
}

export const IngredientNames = (props) => {
    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'ingredients',
        {page:1, perPage:1000}
    );
    const record = useRecordContext()
    const getNames = Object.values(data).map( e => {
        if (e.id === record.ingredient) {
            return <span>{e.title}</span>
        } else return <></>
    })
    return getNames;
}

export default MealEdit;