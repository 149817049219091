import React from "react";
import { useRecordContext } from "react-admin";
import { Check, Cancel } from "@material-ui/icons";

function CountField(props) {
  const { source } = props;
  const record = useRecordContext(props);

  return <span>{record[source]?.length}</span>;
}

export default CountField;
