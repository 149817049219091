import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput
} from 'react-admin';


const IngredientCategoryCreate = (props) => {
    return (
        <Create title='Create a post'  {...props}>
            <SimpleForm redirect={'/ingredient_categories'}>
                <TextInput source="title" label="Kategoria" />
                <ReferenceArrayInput label="Składniki" reference="ingredients" source="ingredients">
                    <SelectArrayInput optionText="title" />
                </ReferenceArrayInput>
            </SimpleForm>

        </Create>
    )
}

export default IngredientCategoryCreate;