import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    RichTextField,
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    ChipField, ReferenceArrayField, TextInput, Filter, CloneButton
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label="Szukaj po nazwie" source="dietName" alwaysOn />
    </Filter>
)

 const DietMaker = (props) => {
    return <List {...props} filters={<SearchFilter />} exporter={false}>
        <Datagrid>
            <TextField source='dietName' label={'Nazwa Diety'} />
            <TextField source='dietNote' label={'Notatka'} />
            <ReferenceField reference={'diet_templates'} label={'Szablon'}  source={'dietTemplate'}>
                <TextField source='dietName' label={'Szablon'} />
            </ReferenceField>
            {/*<RichTextField source="introductionDescription" label="Strona wstępu"/>*/}
            {/*<ReferenceArrayField label="Lista potraw" reference="meals" source={"meals"}>*/}
            {/*    <SingleFieldList>*/}
            {/*        <ChipField source="name" />*/}
            {/*    </SingleFieldList>*/}
            {/*</ReferenceArrayField>*/}
            {/*<RichTextField source="descriptivePages" label="Strony opisowe"/>*/}
            <EditButton basePath='/diets' />
            <CloneButton basePath='/diets'/>
            <DeleteButtonWithConfirmation basePath='/diets' />
        </Datagrid>
    </List>
}

export default DietMaker;